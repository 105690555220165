// brutalist.scss - Reusable brutalist design system

// Primary color variables
$brutalist-primary: #ff3e00;
$brutalist-background: #000;
$brutalist-text-light: #fff;
$brutalist-text-dark: #000;
$brutalist-bg-light: #f7f7f7; // Consistent background color for all screens

// Secondary/UI colors
$brutalist-gray-100: #e8e8e8;  // Light gray backgrounds
$brutalist-gray-200: #ddd;     // Border colors
$brutalist-gray-300: #ccc;     // Light text
$brutalist-gray-400: #999;     // Medium text
$brutalist-gray-500: #777;     // Medium-dark text
$brutalist-gray-600: #666;     // Dark text
$brutalist-gray-700: #555;     // Darker text/borders
$brutalist-gray-800: #444;     // Very dark text
$brutalist-gray-900: #333;     // Nearly black text
$brutalist-gray-950: #222;     // Nearly black backgrounds
$brutalist-dark-bg: #0a0a0a;   // Very dark background

// Dark mode colors
$brutalist-dark-primary: #ff5722;
$brutalist-dark-background: #121212;
$brutalist-dark-surface: #1e1e1e;
$brutalist-dark-text: #f5f5f5;
$brutalist-dark-border: #333;
$brutalist-dark-accent: #ff5722;

// Semantic colors
$brutalist-warning: #ff6347;   // Warning/error color

// Comment Category colors
$brutalist-cat-left: #0d6efd;          // Strong Blue
$brutalist-cat-left-center: #5dade2;   // Lighter Blue
$brutalist-cat-right-center: #f1948a;  // Lighter Red
$brutalist-cat-right: #dc3545;         // Strong Red
$brutalist-cat-other: #{$brutalist-gray-700}; // Dark Gray (#555)

// Export CSS variables for use in JavaScript/TypeScript
:root {
  // Primary colors
  --brutalist-primary: #{$brutalist-primary};
  --brutalist-background: #{$brutalist-background};
  --brutalist-text-light: #{$brutalist-text-light};
  --brutalist-text-dark: #{$brutalist-text-dark};
  --brutalist-bg-light: #{$brutalist-bg-light};
  
  // Gray scale
  --brutalist-gray-100: #{$brutalist-gray-100};
  --brutalist-gray-200: #{$brutalist-gray-200};
  --brutalist-gray-300: #{$brutalist-gray-300};
  --brutalist-gray-400: #{$brutalist-gray-400};
  --brutalist-gray-500: #{$brutalist-gray-500};
  --brutalist-gray-600: #{$brutalist-gray-600};
  --brutalist-gray-700: #{$brutalist-gray-700};
  --brutalist-gray-800: #{$brutalist-gray-800};
  --brutalist-gray-900: #{$brutalist-gray-900};
  --brutalist-gray-950: #{$brutalist-gray-950};
  --brutalist-dark-bg: #{$brutalist-dark-bg};
  
  // Semantic colors
  --brutalist-warning: #{$brutalist-warning};

  // Comment Category colors
  --brutalist-cat-left: #{$brutalist-cat-left};
  --brutalist-cat-left-center: #{$brutalist-cat-left-center};
  --brutalist-cat-right-center: #{$brutalist-cat-right-center};
  --brutalist-cat-right: #{$brutalist-cat-right};
  --brutalist-cat-other: #{$brutalist-cat-other};
  
  // RGB values for use with rgba() in JavaScript
  --brutalist-primary-rgb: #{red($brutalist-primary)}, #{green($brutalist-primary)}, #{blue($brutalist-primary)};
  --brutalist-background-rgb: #{red($brutalist-background)}, #{green($brutalist-background)}, #{blue($brutalist-background)};
  --brutalist-text-light-rgb: #{red($brutalist-text-light)}, #{green($brutalist-text-light)}, #{blue($brutalist-text-light)};
  --brutalist-text-dark-rgb: #{red($brutalist-text-dark)}, #{green($brutalist-text-dark)}, #{blue($brutalist-text-dark)};
  --brutalist-warning-rgb: #{red($brutalist-warning)}, #{green($brutalist-warning)}, #{blue($brutalist-warning)};
}

// Color utility functions
@function brutalist-lighten($color, $percentage) {
  @return lighten($color, $percentage);
}

@function brutalist-darken($color, $percentage) {
  @return darken($color, $percentage);
}

@function brutalist-rgba($color, $alpha) {
  @return rgba($color, $alpha);
}

// Mixins
@mixin brutalist-container {
  border: 6px solid $brutalist-text-dark;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 10px;
}

@mixin brutalist-button {
  background-color: $brutalist-primary;
  border: 4px solid $brutalist-text-dark;
  color: $brutalist-text-dark;
  font-size: 1.2rem;
  font-weight: 800;
  text-transform: uppercase;
  border-radius: 0;
  padding: 10px;
  margin-bottom: 15px;
  transition: transform 0.2s;
  
  &:hover {
    transform: translateY(-3px);
    background-color: lighten($brutalist-primary, 10%);
    border-color: $brutalist-text-dark;
  }
}

@mixin brutalist-title {
  font-weight: 900;
  font-size: 2.5rem;
  letter-spacing: -1px;
  text-transform: uppercase;
}

@mixin brutalist-logo-container {
  margin-bottom: 25px;
  transform: rotate(-2deg);
  display: inline-block;
  background-color: $brutalist-background;
  padding: 10px 20px;
  border: 4px solid $brutalist-background;
  box-shadow: 8px 8px 0 rgba(0, 0, 0, 0.8);
}

@mixin brutalist-grid-container {
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: -15px;
    left: -15px;
    width: 30px;
    height: 30px;
    background-color: $brutalist-primary;
    z-index: 0;
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    background-color: $brutalist-background;
    z-index: 0;
  }
}

@mixin brutalist-link {
  color: $brutalist-primary;
  text-decoration: none;
  font-weight: 800;
  text-transform: uppercase;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $brutalist-primary;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
  }
  
  &:hover::after {
    transform: scaleX(1);
    transform-origin: left;
  }
}

@mixin brutalist-card {
  @include brutalist-container;
  position: relative;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: rotate(-1deg) translateY(-5px);
  }
  
  &::before {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;
    width: 16px;
    height: 16px;
    background-color: $brutalist-primary;
    z-index: 2;
  }
}

@mixin brutalist-form-input {
  border: 3px solid $brutalist-text-dark;
  border-radius: 0;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  font-weight: 600;
  
  &:focus {
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.3);
    border-color: $brutalist-primary;
    outline: none;
  }
}

@mixin brutalist-section-title {
  position: relative;
  display: inline-block;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding: 5px 10px;
  
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: $brutalist-primary;
    z-index: -1;
  }
}

// Reusable classes
.brutalist-container {
  @include brutalist-container;
}

.brutalist-button {
  @include brutalist-button;
}

.brutalist-title {
  @include brutalist-title;
}

.brutalist-title-light {
  @include brutalist-title;
  color: $brutalist-text-light;
}

.brutalist-title-dark {
  @include brutalist-title;
  color: $brutalist-text-dark;
}

.brutalist-title-primary {
  @include brutalist-title;
  color: $brutalist-primary;
}

.brutalist-logo-container {
  @include brutalist-logo-container;
}

.brutalist-grid-container {
  @include brutalist-grid-container;
}

.brutalist-link {
  @include brutalist-link;
}

.brutalist-card {
  @include brutalist-card;
}

.brutalist-form-input {
  @include brutalist-form-input;
}

.brutalist-section-title {
  @include brutalist-section-title;
}

// Dark mode styles
body.dark-mode {
  background-color: $brutalist-dark-background;
  color: $brutalist-dark-text;
  
  .brutalist-container {
    background-color: $brutalist-dark-surface;
    border-color: $brutalist-dark-border;
  }
  
  .brutalist-button {
    background-color: $brutalist-dark-accent;
    border-color: $brutalist-dark-text;
    color: $brutalist-dark-text;
    
    &:hover {
      background-color: lighten($brutalist-dark-accent, 10%);
    }
  }
  
  .brutalist-title-light {
    color: $brutalist-dark-text;
  }
  
  .brutalist-title-dark {
    color: $brutalist-dark-text;
  }
  
  .brutalist-title-primary {
    color: $brutalist-dark-accent;
  }
  
  .brutalist-logo-container {
    background-color: $brutalist-dark-surface;
    border-color: $brutalist-dark-border;
  }
  
  .brutalist-link {
    color: $brutalist-dark-accent;
    
    &::after {
      background-color: $brutalist-dark-accent;
    }
  }
  
  .brutalist-card {
    background-color: $brutalist-dark-surface;
    border-color: $brutalist-dark-border;
    
    &::before {
      background-color: $brutalist-dark-accent;
    }
  }
  
  .brutalist-form-input {
    background-color: $brutalist-dark-surface;
    border-color: $brutalist-dark-border;
    color: $brutalist-dark-text;
    
    &:focus {
      border-color: $brutalist-dark-accent;
    }
  }
  
  .brutalist-section-title {
    &::before {
      background-color: $brutalist-dark-accent;
    }
  }
  
  // Profile page specific dark mode styles
  .profile-container {
    background-color: $brutalist-dark-background;
    color: $brutalist-dark-text;
  }
  
  .profile-card {
    background-color: $brutalist-dark-surface;
    border-color: $brutalist-dark-border;
    
    h2 {
      color: $brutalist-dark-text;
      border-bottom-color: $brutalist-dark-border;
    }
  }
  
  .avatar {
    border-color: $brutalist-dark-border;
    background-color: $brutalist-dark-surface;
  }
  
  .brutalist-file-input {
    background-color: $brutalist-dark-surface;
    color: $brutalist-dark-text;
    border-color: $brutalist-dark-border;
    
    &::file-selector-button {
      background-color: darken($brutalist-dark-surface, 5%);
      color: $brutalist-dark-text;
      border-color: $brutalist-dark-border;
    }
  }
  
  .form-group {
    label {
      color: darken($brutalist-dark-text, 20%);
    }
    
    input {
      background-color: $brutalist-dark-surface;
      color: $brutalist-dark-text;
      border-color: $brutalist-dark-border;
      
      &::placeholder {
        color: darken($brutalist-dark-text, 30%);
      }
    }
  }
  
  .toggle-link {
    color: darken($brutalist-dark-text, 20%);
    
    &:hover {
      color: $brutalist-dark-text;
    }
  }
  
  .error-message {
    background-color: brutalist-rgba($brutalist-warning, 0.2);
    color: brutalist-lighten($brutalist-warning, 20%);
    border-color: brutalist-rgba($brutalist-warning, 0.4);
  }
}

// Social button styles
.brutalist-social-button {
  border-radius: 0 !important;
  font-weight: 800 !important;
  text-transform: uppercase !important;
  margin-bottom: 15px !important;
  transition: transform 0.2s !important;
  
  &:hover {
    transform: translateY(-3px) !important;
  }
}

// Additional utility classes
.brutalist-box-shadow {
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.8);
}

.brutalist-border {
  border: 4px solid $brutalist-text-dark;
  border-radius: 0;
}

.brutalist-background {
  background-color: $brutalist-background;
}

.brutalist-primary-background {
  background-color: $brutalist-primary;
}

.brutalist-text-light {
  color: $brutalist-text-light;
}

.brutalist-text-dark {
  color: $brutalist-text-dark;
}

.brutalist-text-primary {
  color: $brutalist-primary;
}

.brutalist-rotate-1 {
  transform: rotate(-1deg);
}

.brutalist-rotate-2 {
  transform: rotate(-2deg);
}

.brutalist-rotate-3 {
  transform: rotate(-3deg);
}
