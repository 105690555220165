@import '../../../styles/brutalist.scss';

.welcome-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: $brutalist-bg-light;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  &::before, &::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: $brutalist-primary;
    z-index: 0;
  }

  &::before {
    top: 20px;
    left: 20px;
  }

  &::after {
    bottom: 20px;
    right: 20px;
  }
}

.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(0);
  transition: transform 0.5s ease-out;

  &.phase-1 {
    transform: translateY(-10px);
  }
}

.message-card {
  padding: 20px;
  margin: 20px 0;
  max-width: 500px;
  transform: rotate(-1deg);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 8px 8px 0 brutalist-rgba($brutalist-text-dark, 0.2);

  &:hover {
    transform: rotate(0deg) translateY(-8px);
    box-shadow: 12px 12px 0 brutalist-rgba($brutalist-text-dark, 0.3);
  }

  h3 {
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: -0.5px;
  }
}

.brutalist-logo-container {
  margin-bottom: 30px;
  
  h1 {
    margin: 0;
    padding: 10px 20px;
    font-size: 3rem;
  }
}

.tagline {
  opacity: 0;
  height: 0;
  transition: opacity 0.8s ease, height 0.5s ease;
  overflow: hidden;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 15px;
  
  &.visible {
    opacity: 1;
    height: auto;
  }

  span {
    font-size: 1.2rem;
    position: relative;
    display: inline-block;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $brutalist-primary;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform 0.5s ease-out 0.3s;
    }
  }

  &.visible span::after {
    transform: scaleX(1);
    transform-origin: left;
  }
}

.hint-text {
  margin-top: 30px;
  opacity: 0.7;
  font-size: 0.9rem;
  transition: opacity 0.3s ease;
  
  &:hover {
    opacity: 1;
  }
}
