@import '../../../../styles/brutalist.scss';

.brutalist-comment-controls {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 2px solid $brutalist-gray-900;

  .brutalist-control-group {
    display: flex;
    align-items: center;
    gap: 10px;

    label {
      font-weight: bold;
      color: $brutalist-gray-900;
      font-size: 0.9em;
    }

    .brutalist-select {
      padding: 5px 10px;
      border: 2px solid $brutalist-gray-900;
      background-color: $brutalist-bg-light;
      font-size: 0.9em;
      cursor: pointer;
      appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8'%3E%3Cpath fill='%23333' d='M6 8L0 2l1.4-1.4L6 5.2 10.6.6 12 2z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 10px center;
      padding-right: 20px;

      &:focus {
        outline: 2px dashed $brutalist-primary;
        outline-offset: 2px;
      }
    }
  }
}

// Media query for mobile devices
@media (max-width: 600px) {
  .brutalist-comment-controls {
    flex-direction: column;
    align-items: flex-start; // Align items to the start when stacked
    gap: 10px; // Reduce gap for stacked layout

    .brutalist-control-group {
      width: 100%; // Allow groups to take full width if needed
      justify-content: space-between; // Space out label and select

      .brutalist-select {
         flex-grow: 1; // Allow select to grow
         max-width: 200px; // Optional: Limit max width if needed
      }
    }
  }
}
