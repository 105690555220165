@import '../../../styles/brutalist.scss';

.dashboard-layout {
  padding: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  position: relative;
  background-color: $brutalist-bg-light;
}

.dashboard-row {
  height: auto;
  background-color: $brutalist-bg-light;
  min-height: 100vh;
}

/* Dark mode overrides */
.dashboard-layout.dark-mode {
  background-color: $brutalist-dark-background;
  color: $brutalist-dark-text;

  &::before {
    background-color: $brutalist-dark-accent;
  }

  .dashboard-row {
    background-color: $brutalist-dark-background;
    color: $brutalist-dark-text;
  }
}
