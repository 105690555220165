@import '../../../../../styles/brutalist.scss';

// Common styles for navbar elements
.brutalist-navbar {
  position: relative;
}

// Alert counter badge
.brutalist-alert-counter {
  position: absolute;
  top: -4px;
  right: -8px;
  min-width: 18px;
  height: 18px;
  background-color: $brutalist-primary;
  color: white;
  font-size: 11px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding: 0 2px;
  // Add brutalist square style
  border: 1px solid black;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.5);
}

// NavbarFull Styles
.navbar-full {
  background-color: transparent;
  height: 100vh;
  padding: 2rem 0.5rem;
  position: relative;
  z-index: 100;
  
  .navbar-header {
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: -1px;
    margin-bottom: 2.5rem;
    padding: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: xx-large;
    
    a {
      color: $brutalist-background;
      text-decoration: none;
      display: inline-block;
      
      &:hover {
        transform: translateX(3px);
      }
      
      .dark-mode-neg {
        color: $brutalist-text-dark;
      }
    }
  }
  
  .nav-item {
    margin-bottom: 1.5rem;
    position: relative;
    transition: transform 0.2s ease;
    
    &:hover {
      transform: translateX(5px);
      
      &::before {
        content: '';
        position: absolute;
        left: -5px;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        background-color: $brutalist-primary;
      }
    }
    
    &.active, &.menu-active {
      border-left: 4px solid $brutalist-primary;
      
      a {
        color: $brutalist-primary;
        font-weight: 800;
      }
    }
    
    a {
      color: $brutalist-background;
      text-decoration: none;
      display: flex;
      align-items: center;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-size: 0.95rem;
      padding: 0.5rem 1rem;
      
      svg {
        margin-right: 10px;
        font-size: 1.3rem;
      }
      
      &:hover {
        color: $brutalist-primary;
      }
    }
  }
  
  // Hamburger menu styles for NavbarFull
  
  .hamburger-menu-dropdown {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    padding: 0 1rem;
    
    &.show {
      max-height: 500px;
      border-left: 2px solid $brutalist-primary;
    }
  }
  
  .logout-container {
    position: absolute;
    bottom: 2rem;
    left: 0;
    width: 100%;
    padding: 0 1rem;
  }
  
  .brutalist-logout-btn {
    @include brutalist-button;
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    width: 100%;
  }
  
  .navbar-profile {
    position: absolute;
    bottom: 2rem;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 1rem;
    
    .navbar-profile-img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      object-fit: cover;
      display: block;
      margin: 0 auto;
      border: 3px solid black;
      
      transition: transform 0.3s ease, border-color 0.3s ease;
      cursor: pointer;
      
      &:hover {
        transform: rotate(-5deg) scale(1.05);
        border-color: $brutalist-primary;
        box-shadow: 4px 4px 0 $brutalist-dark-bg;
      }
    }
    
    .navbar-profile-username {
      margin-top: 0.5rem;
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      cursor: pointer;
      transition: color 0.3s ease;
      
      &:hover {
        color: $brutalist-primary;
      }
    }
    button {
      background: none;
      border: none;
      outline: none;
      appearance: none;
    }
  }
}

/* Dark mode overrides */
.dark-mode .brutalist-alert-counter {
  background-color: $brutalist-dark-accent;
  color: $brutalist-dark-text;
}

/* Dark mode overrides for NavbarFull */
.dark-mode .navbar-full {
  .navbar-header a {
    color: $brutalist-dark-text;
    
    .dark-mode-neg {
      color: $brutalist-dark-text;
    }
  }
  
  .nav-item {
    &:hover::before {
      background-color: $brutalist-dark-accent;
    }
    
    &.active, &.menu-active {
      border-left-color: $brutalist-dark-accent;
      
      a {
        color: $brutalist-dark-accent;
      }
    }
    
    a {
      color: $brutalist-dark-text;
      
      &:hover {
        color: $brutalist-dark-accent;
      }
    }
  }
  
  
  .hamburger-menu-dropdown {
    &.show {
      border-left-color: $brutalist-dark-accent;
    }
  }
  
  .brutalist-logout-btn {
    background-color: $brutalist-dark-accent;
    border-color: $brutalist-dark-border;
    color: $brutalist-dark-text;
    
    &:hover {
      background-color: lighten($brutalist-dark-accent, 10%);
    }
  }
  
  .navbar-profile {
    .navbar-profile-img {
      border-color: $brutalist-dark-border;
      
      &:hover {
        border-color: $brutalist-dark-accent;
        box-shadow: 4px 4px 0 darken($brutalist-dark-background, 5%);
      }
    }
    
    .navbar-profile-username {
      color: $brutalist-dark-primary;
      
      &:hover {
        color: $brutalist-dark-accent;
      }
    }
  }
}

// NavbarMini Styles
.navbar-mini {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $brutalist-bg-light;
  border-top: 4px solid $brutalist-background;
  height: 4rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 100;
  
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 10px;
    width: 20px;
    height: 10px;
    background-color: $brutalist-primary;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: -10px;
    right: 10px;
    width: 20px;
    height: 10px;
    background-color: $brutalist-primary;
  }
  
  .nav-item {
    position: relative;
    padding: 0.5rem;
    
    &.active, &.menu-active {
      a {
        color: $brutalist-primary;
        transform: translateY(-5px);
      }
      
      &::after {
        content: '';
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        width: 8px;
        height: 8px;
        background-color: $brutalist-primary;
      }
    }
    
    a {
      color: $brutalist-background;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 700;
      font-size: 0.7rem;
      transition: transform 0.2s ease;
      
      svg {
        font-size: 1.5rem;
        margin-bottom: 3px;
      }
      
      &:hover {
        color: $brutalist-primary;
        transform: translateY(-3px);
      }
    }
  }
  
  .navbar-mini-dropdown {
    position: absolute;
    bottom: 4.5rem;
    right: 0;
    width: 100%;
    max-width: 250px;
    background-color: $brutalist-bg-light;
    border: 3px solid $brutalist-background;
    border-radius: 2px;
    padding: 1rem;
    z-index: 101;
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.1);
    animation: slideUp 0.3s ease-in-out;
    
    .nav-item {
      margin-bottom: 1rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 0.5rem;
      
      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }
    }
    
    @keyframes slideUp {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

/* Dark mode overrides for NavbarMini */
.dark-mode .navbar-mini {
  background-color: $brutalist-dark-background;
  border-top-color: $brutalist-dark-border;
  
  &::before,
  &::after {
    background-color: $brutalist-dark-accent;
  }
  
  .nav-item {
    &.active, &.menu-active {
      a {
        color: $brutalist-dark-accent;
      }
      
      &::after {
        background-color: $brutalist-dark-accent;
      }
    }
    
    a {
      color: $brutalist-dark-text;
      
      &:hover {
        color: $brutalist-dark-accent;
      }
    }
    
  }
  
  .navbar-mini-dropdown {
    background-color: $brutalist-dark-background;
    border-color: $brutalist-dark-border;
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.3);
    
    .nav-item {
      border-bottom-color: rgba(255, 255, 255, 0.1);
    }
  }
}
