@import '../../../../styles/brutalist.scss';

.left-side-panel {
  border-right: 4px solid $brutalist-background;
  background-color: $brutalist-bg-light;
  padding: 0;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background-color: $brutalist-primary;
  }
}

/* Dark mode overrides */
.dark-mode .left-side-panel {
  border-right-color: $brutalist-dark-border;
  background-color: $brutalist-dark-background;
  
  &::after {
    background-color: $brutalist-dark-accent;
  }
}
