@import '../../../../styles/brutalist.scss';

.right-side-panel {
  background-color: $brutalist-bg-light;
  position: relative;
  
  .side-panel-border {
    border-left: 4px solid $brutalist-background;
    height: 100%;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      bottom: 20px;
      left: -15px;
      width: 10px;
      height: 10px;
      background-color: $brutalist-primary;
    }
  }
}

/* Dark mode overrides */
.dark-mode .right-side-panel {
  background-color: $brutalist-dark-background;
  color: $brutalist-dark-text;
  
  .side-panel-border {
    border-left-color: $brutalist-dark-border;
    
    &::before {
      background-color: $brutalist-dark-accent;
    }
  }
}
