@import '../../../styles/brutalist.scss';

.alerts-content {
  font-family: var(--brutalist-font);
  background-color: var(--brutalist-light);
  border: 2px solid var(--brutalist-dark);
  padding: 20px;
  margin-bottom: 20px;
  
  .alerts-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--brutalist-dark);
    padding-bottom: 10px;
    
    h3 {
      font-weight: bold;
      margin: 0;
      text-transform: uppercase;
    }
    
    .actions-container {
      display: flex;
      gap: 10px;
    }
  }
  
  .alerts-list {
    list-style: none;
    padding: 0;
    margin: 0;
    
    .alert-item {
      padding: 15px;
      margin-bottom: 10px;
      border: 1px solid var(--brutalist-dark);
      position: relative;
      transition: all 0.2s ease;
      
      &.unread {
        border-left: 5px solid var(--brutalist-primary);
        background-color: rgba(var(--brutalist-primary-rgb), 0.05);
      }
      
      .alert-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        
        .alert-type {
          font-weight: bold;
          text-transform: uppercase;
          font-size: 0.8rem;
          
          &.welcome {
            color: var(--brutalist-primary);
          }
          
          &.admin_critical {
            color: #ff0000;
            font-weight: 900;
          }
          
          &.admin_general {
            color: #0066cc;
          }
          
          &.comment_reply {
            color: var(--brutalist-secondary);
          }
          
          &.trending_discussion {
            color: var(--brutalist-accent);
          }
          
          &.breaking_news {
            color: #ff0000;
          }
          
          &.system_notification {
            color: var(--brutalist-dark);
          }
        }
        
        .alert-date {
          font-size: 0.8rem;
          color: #666;
        }
      }
      
      .alert-message {
        margin-bottom: 10px;
      }
      
      .alert-actions {
        text-align: right;
        
        button {
          background: none;
          border: none;
          color: var(--brutalist-primary);
          text-decoration: underline;
          cursor: pointer;
          font-size: 0.8rem;
          
          &:hover {
            color: var(--brutalist-secondary);
          }
        }
      }
    }
  }
  
  .load-more-container {
    text-align: center;
    margin-top: 20px;
    
    button {
      background-color: var(--brutalist-light);
      border: 2px solid var(--brutalist-dark);
      padding: 8px 15px;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.2s ease;
      
      &:hover {
        background-color: var(--brutalist-dark);
        color: var(--brutalist-light);
      }
      
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
  
  .empty-alerts {
    text-align: center;
    padding: 30px;
    
    h3 {
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    
    p {
      color: #666;
    }
  }
  
  .brutalist-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    
    .loading-indicator {
      width: 40px;
      height: 40px;
      border: 4px solid var(--brutalist-light);
      border-top: 4px solid var(--brutalist-primary);
      border-radius: 50%;
      animation: spin 1s linear infinite;
      margin-bottom: 15px;
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
  
  .brutalist-error {
    padding: 20px;
    border: 2px solid #ff0000;
    background-color: rgba(255, 0, 0, 0.1);
    text-align: center;
    
    h3 {
      color: #ff0000;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
  }
}

.mark-all-button {
  background-color: var(--brutalist-light);
  border: 2px solid var(--brutalist-dark);
  padding: 5px 10px;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: var(--brutalist-dark);
    color: var(--brutalist-light);
  }
}