@import '../../../styles/brutalist.scss';

.profile-container {
  background-color: $brutalist-bg-light;
  color: $brutalist-text-dark;
  min-height: 100vh;
  padding: 30px;
  position: relative;
  overflow: hidden;
  
  // Brutalist decorative elements
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background-color: $brutalist-primary;
    z-index: 1;
  }
}

.profile-header {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 0 40px 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  h2 {
    margin: 0;
    font-size: 2.2rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: -1px;
    transform: rotate(-1deg);
    padding: 5px 15px;
    background-color: $brutalist-primary;
    color: $brutalist-text-light;
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
  }
}

.dark-mode-toggle {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 10;
}

.dark-mode-button {
  background-color: $brutalist-gray-900;
  border: 3px solid $brutalist-primary;
  color: $brutalist-text-light;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.3);
    background-color: $brutalist-primary;
  }
  
  &:focus {
    outline: none;
  }
}

.profile-back-button {
  position: absolute;
  left: 30px;
  top: 30px;
  background-color: $brutalist-gray-900;
  border: 3px solid $brutalist-primary;
  color: $brutalist-text-light;
  z-index: 10;
  
  &:hover {
    background-color: $brutalist-primary;
    transform: translateX(-3px);
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.3);
  }
}

.profile-content {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: 40px;
  max-width: 1000px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  
  // Updated for the settings section card
  .profile-card:nth-child(3) {
    grid-column: 1 / -1;
  }
}

.profile-card {
  background-color: $brutalist-gray-100;
  border: 4px solid $brutalist-text-dark;
  padding: 30px;
  position: relative;
  transform: rotate(1deg);
  box-shadow: 8px 8px 0 rgba(0, 0, 0, 0.2);
  
  &::before {
    content: '';
    position: absolute;
    top: -15px;
    left: -15px;
    width: 30px;
    height: 30px;
    background-color: $brutalist-primary;
    z-index: 2;
  }
  
  h2 {
    margin-bottom: 25px;
    color: $brutalist-text-dark;
    font-size: 1.5rem;
    font-weight: 800;
    text-transform: uppercase;
    border-bottom: 3px solid $brutalist-primary;
    padding-bottom: 10px;
    transform: rotate(-1deg);
    display: inline-block;
  }
}

.profile-picture {
  transform: rotate(-1deg);
  margin-bottom: 30px;
}

.avatar-container {
  position: relative;
  margin-bottom: 30px;
  
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: 10px;
    bottom: 10px;
    border: 2px solid $brutalist-primary;
    z-index: -1;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    width: 100%;
    height: 100%;
    background-color: $brutalist-gray-300;
    z-index: -2;
  }
}

.avatar {
  width: 180px;
  height: 180px;
  border-radius: 0;
  border: 4px solid $brutalist-text-dark;
  background-color: $brutalist-gray-200;
  object-fit: cover;
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.2);
}

.file-input-container {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
  
  &::before {
    content: 'CHOOSE FILE';
    position: absolute;
    top: -25px;
    left: 0;
    font-size: 0.8rem;
    font-weight: 800;
    color: $brutalist-primary;
    letter-spacing: 1px;
  }
}

.brutalist-file-input {
  background-color: $brutalist-gray-200;
  color: $brutalist-text-dark;
  border: 3px solid $brutalist-text-dark;
  border-radius: 0;
  padding: 15px;
  width: 100%;
  font-weight: 600;
  
  &:focus {
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
    border-color: $brutalist-primary;
    outline: none;
  }
  
  &::file-selector-button {
    background-color: $brutalist-primary;
    color: $brutalist-text-light;
    border: 2px solid $brutalist-text-dark;
    border-radius: 0;
    padding: 8px 15px;
    margin-right: 15px;
    font-size: 0.9em;
    font-weight: 700;
    text-transform: uppercase;
    transition: all 0.2s;
    
    &:hover {
      background-color: $brutalist-text-dark;
      color: $brutalist-text-light;
      transform: translateY(-2px);
    }
  }
}

.profile-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 20px;
  width: 100%;
  
  .btn {
    background-color: $brutalist-text-dark;
    color: $brutalist-text-light;
    border: 3px solid $brutalist-text-dark;
    border-radius: 0;
    padding: 10px 20px;
    transition: all 0.3s;
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    
    &:hover, &:focus {
      transform: translateY(-3px);
      box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
    }
    
    &.btn-primary {
      background-color: $brutalist-primary;
      border-color: $brutalist-text-dark;
      
      &:hover, &:focus {
        background-color: brutalist-darken($brutalist-primary, 10%);
      }
    }
    
    &.btn-danger {
      background-color: $brutalist-warning;
      border-color: $brutalist-text-dark;
      
      &:hover, &:focus {
        background-color: brutalist-darken($brutalist-warning, 10%);
      }
    }
    
    &.brutalist-button {
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 1px;
      box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.2);
      
      &:hover, &:focus {
        transform: translateY(-3px);
        box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.3);
      }
    }
  }
}

.form-group {
  margin-bottom: 25px;
  position: relative;
  
  label {
    display: block;
    margin-bottom: 8px;
    color: $brutalist-text-dark;
    font-size: 0.9em;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  input {
    background-color: $brutalist-gray-200;
    color: $brutalist-text-dark;
    border: 3px solid $brutalist-text-dark;
    border-radius: 0;
    padding: 12px 15px;
    width: 100%;
    font-weight: 600;
    transition: all 0.2s;
    
    &:focus {
      box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
      border-color: $brutalist-primary;
      outline: none;
      transform: translateY(-2px);
    }
    
    &::placeholder {
      color: $brutalist-gray-600;
      font-size: 0.9em;
    }
  }
  
  // Removed the rotation transforms to make fields horizontal
}

.toggle-link {
  display: flex;
  align-items: center;
  color: $brutalist-text-dark;
  margin: 20px 0;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.2s;
  padding: 10px;
  border-left: 5px solid $brutalist-primary;
  
  &:hover {
    color: $brutalist-primary;
    text-decoration: none;
    transform: translateX(5px);
  }
  
  svg {
    margin-right: 10px;
    font-size: 1.2em;
  }
}

.error-message {
  background-color: $brutalist-warning;
  color: $brutalist-text-light;
  padding: 15px;
  margin-bottom: 20px;
  border: 3px solid $brutalist-text-dark;
  font-size: 0.9em;
  font-weight: 600;
  position: relative;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
  
  &::before {
    content: '!';
    position: absolute;
    top: -10px;
    left: -10px;
    width: 25px;
    height: 25px;
    background-color: $brutalist-text-dark;
    color: $brutalist-text-light;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
  }
}

.back-link {
  color: $brutalist-text-dark;
  text-decoration: none;
  margin-top: 30px;
  font-size: 0.9em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  padding: 5px 0;
  border-bottom: 2px solid $brutalist-primary;
  transition: all 0.2s;
  
  &:hover {
    color: $brutalist-primary;
    transform: translateX(-5px);
  }
}

/* Image Crop Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: brutalist-rgba($brutalist-text-dark, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-crop-modal {
  background-color: $brutalist-bg-light;
  border: 5px solid $brutalist-text-dark;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 0;
  box-shadow: 15px 15px 0 rgba(0, 0, 0, 0.3);
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: -15px;
    left: -15px;
    width: 30px;
    height: 30px;
    background-color: $brutalist-primary;
    z-index: 2;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px;
    border-bottom: 3px solid $brutalist-text-dark;
    background-color: $brutalist-primary;
    
    h3 {
      margin: 0;
      color: $brutalist-text-light;
      font-size: 1.4rem;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: -0.5px;
    }
    
    .close-button {
      background: $brutalist-text-dark;
      border: 2px solid $brutalist-text-light;
      color: $brutalist-text-light;
      font-size: 1.5rem;
      font-weight: 700;
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      padding: 0;
      
      &:hover {
        background-color: $brutalist-warning;
        transform: rotate(90deg);
        transition: transform 0.3s;
      }
    }
  }
  
  .crop-container {
    position: relative;
    height: 350px;
    width: 100%;
    background-color: $brutalist-gray-200;
    border-bottom: 3px solid $brutalist-text-dark;
  }
  
  .controls {
    padding: 20px 25px;
    border-bottom: 3px solid $brutalist-text-dark;
    background-color: $brutalist-gray-100;
    
    .zoom-control {
      display: flex;
      flex-direction: column;
      
      label {
        margin-bottom: 10px;
        color: $brutalist-text-dark;
        font-size: 0.9em;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
      
      input[type="range"] {
        width: 100%;
        background-color: $brutalist-gray-300;
        height: 10px;
        -webkit-appearance: none;
        border-radius: 0;
        border: 2px solid $brutalist-text-dark;
        
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 25px;
          height: 25px;
          background-color: $brutalist-primary;
          border: 2px solid $brutalist-text-dark;
          border-radius: 0;
          cursor: pointer;
        }
        
        &::-moz-range-thumb {
          width: 25px;
          height: 25px;
          background-color: $brutalist-primary;
          border: 2px solid $brutalist-text-dark;
          border-radius: 0;
          cursor: pointer;
        }
      }
    }
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    padding: 20px 25px;
    border-top: 3px solid $brutalist-text-dark;
    background-color: $brutalist-gray-100;
  }
  
  .image-requirements {
    padding: 20px 25px;
    background-color: $brutalist-gray-200;
    border-top: 3px solid $brutalist-text-dark;
    
    h4 {
      margin-top: 0;
      margin-bottom: 15px;
      color: $brutalist-text-dark;
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    
    ul {
      margin: 0;
      padding-left: 20px;
      color: $brutalist-text-dark;
      font-size: 0.9rem;
      
      li {
        margin-bottom: 8px;
        font-weight: 500;
      }
    }
  }
}

/* Additional styles for ProfilePicture component */
.selected-image-info {
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: $brutalist-gray-200;
  border: 3px solid $brutalist-text-dark;
  font-size: 0.9em;
  color: $brutalist-text-dark;
  font-weight: 600;
  position: relative;
  
  p {
    margin: 0;
  }
}

.upload-limits-info {
  margin-top: 20px;
  padding: 15px;
  background-color: $brutalist-gray-200;
  border: 3px solid $brutalist-text-dark;
  font-size: 0.85em;
  color: $brutalist-text-dark;
  text-align: center;
  position: relative;
  
  p {
    margin: 0;
  }
  
  small {
    font-size: 0.9em;
    font-weight: 600;
  }
}

.settings-section-container {
  margin-top: 1rem;
  background-color: $brutalist-gray-200;
  border: 3px solid $brutalist-text-dark;
  padding: 1.5rem;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
  
  .section-content {
    margin-top: 0.5rem;
  }
  
  .settings-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .setting-group {
    h3 {
      margin-top: 0;
      margin-bottom: 1.25rem;
      font-size: 1.1rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      border-bottom: 2px solid $brutalist-primary;
      display: inline-block;
      padding-bottom: 0.25rem;
    }
  }
  
  .setting-item {
    margin-bottom: 1rem;
    
    .setting-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0;
      
      span {
        font-weight: 600;
      }
    }
  }
  
  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    
    input {
      opacity: 0;
      width: 0;
      height: 0;
      
      &:checked + .slider {
        background-color: $brutalist-primary;
      }
      
      &:checked + .slider:before {
        transform: translateX(26px);
      }
    }
    
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $brutalist-gray-400;
      transition: .4s;
      border: 2px solid $brutalist-text-dark;
      
      &:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: $brutalist-text-light;
        transition: .4s;
        border: 1px solid $brutalist-text-dark;
      }
    }
  }
  
  select {
    padding: 0.5rem;
    border: 2px solid $brutalist-text-dark;
    background-color: $brutalist-gray-100;
    font-family: inherit;
    font-weight: 600;
    
    &:focus {
      outline: none;
      border-color: $brutalist-primary;
    }
  }
}

/* Dark mode styles */
.profile-container.dark-mode {
  background-color: $brutalist-dark-background;
  color: $brutalist-dark-text;
  
  &::before, &::after {
    border-color: $brutalist-dark-accent;
  }
  
  .profile-header h2 {
    background-color: $brutalist-dark-accent;
    color: $brutalist-dark-text;
  }
  
  .settings-section-container {
    background-color: $brutalist-dark-surface;
    border-color: $brutalist-dark-border;
    
    .setting-group h3 {
      color: $brutalist-dark-text;
      border-bottom-color: $brutalist-dark-accent;
    }
    
    .setting-item .setting-label span {
      color: $brutalist-dark-text;
    }
    
    .toggle-switch {
      .slider {
        background-color: $brutalist-gray-700;
        border-color: $brutalist-dark-border;
        
        &:before {
          background-color: $brutalist-gray-300;
          border-color: $brutalist-dark-border;
        }
      }
      
      input:checked + .slider {
        background-color: $brutalist-dark-accent;
      }
    }
    
    select {
      background-color: $brutalist-dark-surface;
      color: $brutalist-dark-text;
      border-color: $brutalist-dark-border;
      
      &:focus {
        border-color: $brutalist-dark-accent;
      }
    }
  }
  
  .profile-card {
    background-color: $brutalist-dark-surface;
    border-color: $brutalist-dark-border;
    
    &::before {
      background-color: $brutalist-dark-accent;
    }
    
    h2 {
      color: $brutalist-dark-text;
      border-bottom-color: $brutalist-dark-accent;
    }
  }
  
  .avatar-container {
    &::before {
      border-color: $brutalist-dark-accent;
    }
    
    &::after {
      background-color: $brutalist-gray-800;
    }
  }
  
  .avatar {
    border-color: $brutalist-dark-border;
    background-color: $brutalist-gray-900;
  }
  
  .file-input-container::before {
    color: $brutalist-dark-accent;
  }
  
  .brutalist-file-input {
    background-color: $brutalist-dark-surface;
    color: $brutalist-dark-text;
    border-color: $brutalist-dark-border;
    
    &::file-selector-button {
      background-color: $brutalist-dark-accent;
      color: $brutalist-dark-text;
      border-color: $brutalist-dark-border;
      
      &:hover {
        background-color: $brutalist-dark-border;
      }
    }
  }
  
  .form-group {
    label {
      color: $brutalist-dark-text;
    }
    
    input {
      background-color: $brutalist-dark-surface;
      color: $brutalist-dark-text;
      border-color: $brutalist-dark-border;
      
      &::placeholder {
        color: $brutalist-gray-600;
      }
    }
  }
  
  .toggle-link {
    color: $brutalist-dark-text;
    border-left-color: $brutalist-dark-accent;
    
    &:hover {
      color: $brutalist-dark-accent;
    }
  }
  
  .selected-image-info {
    background-color: $brutalist-dark-surface;
    border-color: $brutalist-dark-border;
    color: $brutalist-dark-text;
  }
  
  .upload-limits-info {
    background-color: $brutalist-dark-surface;
    border-color: $brutalist-dark-border;
    color: $brutalist-gray-400;
  }
}
