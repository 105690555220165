@import '../../../styles/brutalist.scss';
@import './DiscussionComponents/CommentControls.scss';

:root {
  --comment-bg: #{$brutalist-gray-100};
  --reply-bg: #{$brutalist-gray-200};
  --rebuttal-bg: #{$brutalist-gray-300};
  --comment-border: #{$brutalist-gray-900};
  --reply-border: #{$brutalist-gray-950};
  --rebuttal-border: #{$brutalist-gray-950};
  --comment-accent: #{$brutalist-primary};
  --reply-line: #{$brutalist-gray-900};
  --rebuttal-line: #{$brutalist-primary};
}

.brutalist-comment-system {
  margin: 20px 0;
  position: relative;
  
  .brutalist-section-title {
    margin-bottom: 20px;
    color: $brutalist-text-dark;
    font-size: 1.3rem;
    border-bottom: 1px solid $brutalist-gray-800;
    padding-bottom: 5px;
    display: inline-block;
  }

  .comment-tree {
    position: relative;
  }
  
  .comment-node {
    position: relative;
  }
  
  .reply-node {
    position: relative;
    margin-left: 30px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -20px;
      height: 100%;
      width: 2px;
      background-color: var(--reply-line);
    }

    &::after {
      content: '';
      position: absolute;
      top: 15px;
      left: -20px;
      height: 2px;
      width: 20px;
      background-color: var(--reply-line);
    }
  }

  .rebuttal-node {
    position: relative;
    margin-left: 30px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -20px;
      height: 100%;
      width: 2px;
      background-color: var(--rebuttal-line);
    }

    &::after {
      content: '';
      position: absolute;
      top: 15px;
      left: -20px;
      height: 2px;
      width: 20px;
      background-color: var(--rebuttal-line);
    }
  }

  textarea.form-control {
    background-color: var(--comment-bg);
    color: $brutalist-text-dark;
    border: 2px solid var(--comment-border);
    border-radius: 0;
    padding: 12px;
    margin-bottom: 10px;
    
    &:focus {
      box-shadow: none;
      border-color: $brutalist-primary;
      outline: none;
    }
    
    &::placeholder {
      color: $brutalist-gray-600;
      font-size: 0.9em;
    }
  }

  .btn {
    background-color: $brutalist-gray-950;
    color: $brutalist-gray-300;
    border: 2px solid $brutalist-gray-800;
    border-radius: 0;
    padding: 5px 15px;
    margin-right: 10px;
    margin-top: 5px;
    transition: background-color 0.2s;
    font-size: 0.85em;
    
    &:hover, &:focus {
      background-color: $brutalist-gray-900;
      color: $brutalist-text-dark;
      border-color: $brutalist-primary;
    }
    
    &.btn-primary {
      background-color: $brutalist-gray-900;
      border-color: $brutalist-gray-800;
      
      &:hover, &:focus {
        background-color: $brutalist-gray-800;
      }
    }
    
    &.brutalist-button {
      text-transform: none;
      font-weight: normal;
      letter-spacing: normal;
      box-shadow: none;
      
      &:hover, &:focus {
        transform: none;
        box-shadow: none;
      }
    }
  }

  .character-count {
    font-family: monospace;
    color: $brutalist-gray-500;
    font-size: 0.8em;
    margin-bottom: 10px;
    text-align: right;
    
    &.warning {
      color: $brutalist-warning;
    }
  }

  .confirmation-dialog {
    border: 2px solid var(--comment-border);
    background-color: var(--comment-bg);
    color: $brutalist-text-dark;
    
    .modal-header, .modal-footer {
      border-color: var(--comment-border);
    }
  }
  
  .brutalist-comment-box {
    margin-bottom: 20px;
  }
  
  .brutalist-action-button {
    background: none;
    border: none;
    color: $brutalist-gray-400;
    padding: 0;
    margin: 5px 0;
    font-size: 0.85em;
    position: relative;
    
    &:hover, &:focus {
      color: $brutalist-text-dark;
      background: none;
      border: none;
      text-decoration: underline;
    }
    
    &.brutalist-small {
      font-size: 0.8em;
    }
  }

  .rebuttal-thread-header {
    background-color: $brutalist-gray-950;
    border-left: 3px solid $brutalist-primary;
    padding: 8px 12px;
    margin: 10px 0;
    font-family: monospace;
  }
  
  .rebuttal-thread-title {
    font-weight: bold;
    color: $brutalist-primary;
    font-size: 0.9em;
    letter-spacing: 1px;
  }
  
  .rebuttal-participants {
    color: $brutalist-gray-400;
    font-size: 0.8em;
    margin-top: 3px;
  }

  .rebuttal-node:last-child::before {
    height: 15px;
  }

  .reply-node:last-child::before {
    height: 15px;
  }

  .comment-container {
    margin-bottom: 20px;
    border: none;
    background-color: transparent;
    
    .brutalist-action-button {
      position: absolute;
      bottom: 10px;
      right: 10px;
      z-index: 2;
    }
  }
  
  .reply-container {
    margin-bottom: 15px;
    border: none;
    background-color: transparent;
    position: relative;
    
    .brutalist-action-button {
      position: absolute;
      bottom: 10px;
      right: 10px;
      z-index: 2;
    }
  }
  
  .rebuttal-container {
    margin-bottom: 10px;
    border: none;
    background-color: transparent;
    position: relative;
    
    .brutalist-action-button {
      position: absolute;
      bottom: 10px;
      right: 10px;
      z-index: 2;
    }
  }

  .comment-wrapper, .reply-wrapper, .rebuttal-wrapper {
    position: relative;
    padding-bottom: 35px;
  }

  .brutalist-category-selector {
    margin-top: 10px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .category-label {
      margin-right: 10px;
      font-size: 0.9em;
      color: $brutalist-gray-600;
      font-weight: bold;
    }

    .brutalist-category-button {
      @include brutalist-button;
      padding: 4px 10px;
      font-size: 0.75em;
      margin-right: 5px;
      margin-bottom: 5px;
      border-width: 2px;
      background-color: $brutalist-gray-200;
      color: $brutalist-text-dark;
      border-color: $brutalist-gray-400;
      transition: all 0.2s ease;

      &:hover {
        transform: none;
        filter: brightness(95%);
      }

      &.selected {
        border-width: 3px;
        font-weight: bold;
        filter: brightness(100%);
        box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.4);
      }

      &.category--left { border-color: $brutalist-cat-left; &.selected { background-color: lighten($brutalist-cat-left, 35%); } }
      &.category--left-center { border-color: $brutalist-cat-left-center; &.selected { background-color: lighten($brutalist-cat-left-center, 30%); } }
    &.category--right-center { border-color: $brutalist-cat-right-center; &.selected { background-color: lighten($brutalist-cat-right-center, 20%); } }
    &.category--right { border-color: $brutalist-cat-right; &.selected { background-color: lighten($brutalist-cat-right, 35%); } }
    &.category--other { border-color: $brutalist-cat-other; &.selected { background-color: lighten($brutalist-gray-700, 40%); } }
    }
  }

  .comment-node {
    border-left: 4px solid transparent;
    padding-left: 10px;

    &.category--left { border-left-color: $brutalist-cat-left; }
    &.category--left-center { border-left-color: $brutalist-cat-left-center; }
    &.category--right-center { border-left-color: $brutalist-cat-right-center; }
    &.category--right { border-left-color: $brutalist-cat-right; }
    &.category--other { border-left-color: $brutalist-cat-other; }
  }

  .reply-node::before, .reply-node::after { left: -20px; }
  .rebuttal-node::before, .rebuttal-node::after { left: -20px; }

}

.light-mode {
  .brutalist-comment-system {
    --comment-bg: #{$brutalist-bg-light};
    --reply-bg: #{$brutalist-gray-100};
    --rebuttal-bg: #{$brutalist-gray-200};
    --comment-border: #{$brutalist-gray-300};
    --reply-border: #{$brutalist-gray-200};
    --rebuttal-border: #{$brutalist-gray-300};
    --comment-accent: #{$brutalist-primary};
    --reply-line: #{$brutalist-gray-400};
    --rebuttal-line: #{$brutalist-primary};
  }
}

.light-mode {
  .brutalist-comment-system {
    --comment-bg: #{$brutalist-bg-light};
    --reply-bg: #{$brutalist-gray-100};
    --rebuttal-bg: #{$brutalist-gray-200};
    --comment-border: #{$brutalist-gray-300};
    --reply-border: #{$brutalist-gray-200};
    --rebuttal-border: #{$brutalist-gray-300};
    --comment-accent: #{$brutalist-primary};
    --reply-line: #{$brutalist-gray-400};
    --rebuttal-line: #{$brutalist-primary};
  }
}

#post-login-container.dark-mode {
  .brutalist-comment-system {
    .brutalist-category-selector {
      .category-label {
        color: $brutalist-gray-400;
      }

      .brutalist-category-button {
        background-color: $brutalist-gray-800;
        color: $brutalist-dark-text;
        border-color: $brutalist-gray-600;

        &:hover {
          filter: brightness(110%);
        }

        &.selected {
          border-width: 3px;
          filter: brightness(100%);
          box-shadow: 2px 2px 0 rgba(255, 255, 255, 0.1);
        }

        &.category--left { border-color: lighten($brutalist-cat-left, 15%); &.selected { background-color: darken($brutalist-cat-left, 25%); } }
        &.category--left-center { border-color: lighten($brutalist-cat-left-center, 15%); &.selected { background-color: darken($brutalist-cat-left-center, 25%); } }
        &.category--right-center { border-color: lighten($brutalist-cat-right-center, 10%); &.selected { background-color: darken($brutalist-cat-right-center, 25%); } }
        &.category--right { border-color: lighten($brutalist-cat-right, 15%); &.selected { background-color: darken($brutalist-cat-right, 25%); } }
        &.category--other { border-color: lighten($brutalist-gray-700, 20%); &.selected { background-color: darken($brutalist-gray-700, 10%); } }
      }
    }

    .comment-node {
      &.category--left { border-left-color: lighten($brutalist-cat-left, 10%); }
      &.category--left-center { border-left-color: lighten($brutalist-cat-left-center, 10%); }
      &.category--right-center { border-left-color: lighten($brutalist-cat-right-center, 5%); }
      &.category--right { border-left-color: lighten($brutalist-cat-right, 10%); }
      &.category--other { border-left-color: lighten($brutalist-gray-700, 15%); }
    }

    --comment-bg: #{$brutalist-dark-background};
    --reply-bg: #{$brutalist-dark-surface};
    --rebuttal-bg: #{$brutalist-gray-900};
    --comment-border: #{$brutalist-dark-border};
    --reply-border: #{$brutalist-dark-border};
    --rebuttal-border: #{$brutalist-dark-border};
    --comment-accent: #{$brutalist-dark-accent};
    --reply-line: #{$brutalist-dark-border};
    --rebuttal-line: #{$brutalist-dark-accent};
        
    textarea.form-control {
      background-color: $brutalist-dark-surface;
      color: $brutalist-dark-text;
      border-color: $brutalist-dark-border;
      
      &:focus {
        border-color: $brutalist-dark-accent;
      }
      
      &::placeholder {
        color: $brutalist-gray-600;
      }
    }
    
    .btn {
      background-color: $brutalist-dark-surface;
      color: $brutalist-dark-text;
      border-color: $brutalist-dark-border;
      
      &:hover, &:focus {
        background-color: lighten($brutalist-dark-surface, 5%);
        color: $brutalist-dark-text;
        border-color: $brutalist-dark-accent;
      }
      
      &.btn-primary {
        background-color: $brutalist-dark-accent;
        border-color: darken($brutalist-dark-accent, 10%);
        
        &:hover, &:focus {
          background-color: lighten($brutalist-dark-accent, 5%);
        }
      }
    }
    
    .character-count {
      color: $brutalist-gray-500;
      
      &.warning {
        color: $brutalist-warning;
      }
    }
    
    .confirmation-dialog {
      border-color: $brutalist-dark-border;
      background-color: $brutalist-dark-surface;
      color: $brutalist-dark-text;
      
      .modal-header, .modal-footer {
        border-color: $brutalist-dark-border;
      }
    }
    
    .brutalist-action-button {
      color: $brutalist-gray-500;
      
      &:hover, &:focus {
        color: $brutalist-dark-text;
      }
    }
    
    .rebuttal-thread-header {
      background-color: darken($brutalist-dark-surface, 3%);
      border-left-color: $brutalist-dark-accent;
    }
    
    .rebuttal-thread-title {
      color: $brutalist-dark-accent;
    }
    
  .rebuttal-participants {
    color: $brutalist-gray-500;
  }
}

// Media query for mobile devices
@media (max-width: 600px) {
  .brutalist-comment-system {
    .brutalist-category-selector {
      .brutalist-category-button {
        padding: 3px 8px; // Slightly reduce padding
        font-size: 0.7em; // Slightly reduce font size
        margin-right: 4px; // Reduce right margin
        margin-bottom: 4px; // Reduce bottom margin
      }
    }

    // Optional: Adjust reply/rebuttal indentation if needed
    .reply-node, .rebuttal-node {
      margin-left: 20px; // Reduce left margin
      &::before, &::after {
        left: -15px; // Adjust line position
      }
      &::after {
        width: 15px; // Adjust horizontal line width
      }
    }
  }
}

  .brutalist-comment-system h2.brutalist-section-title {
    color: $brutalist-dark-text;
  }
}
