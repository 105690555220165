@import './styles/brutalist.scss';
@import './components/MainComponents/FollowingFeed/FollowingFeed.scss';

.app-wrapper {
  min-height: 100vh;
  transition: background-color 0.3s ease, color 0.3s ease;
}

@media (max-width: 575.98px) {
  .navbar-mini {
    display: flex;
  }
  
  .navbar-full {
    display: none;
  }
}

@media (min-width: 575.98px) {
  .navbar-mini {
    display: none;
  }
  
  .navbar-full {
    display: block;
  }
}

.avatar {
  vertical-align: middle;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border-width: 50%;
  border-color: $brutalist-text-dark;
  border-style: outset;
}