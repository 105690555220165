@import '../../../styles/brutalist.scss';

.followers-feed-container {
  font-family: var(--brutalist-font);
  padding: 20px;
  margin-bottom: 20px;
  max-width: 800px;
  margin: 0 auto;
  
  .followers-feed-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 3px solid $brutalist-text-dark;
    padding-bottom: 10px;
    
    h2 {
      @include brutalist-section-title;
      margin: 0;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 900;
    }
    
    .manage-muted-btn {
      @include brutalist-button;
      padding: 8px 16px;
      font-size: 14px;
      margin-bottom: 0;
    }
  }
  
  .muted-users-container {
    @include brutalist-container;
    margin-bottom: 30px;
    padding: 15px;
    
    h3 {
      @include brutalist-section-title;
      margin-top: 0;
      margin-bottom: 15px;
      font-size: 18px;
    }
    
    .muted-users-list {
      list-style: none;
      padding: 0;
      margin: 0;
      
      .muted-user-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        border-bottom: 2px solid $brutalist-gray-200;
        
        &:last-child {
          border-bottom: none;
        }
        
        .muted-user-info {
          display: flex;
          align-items: center;
          
          .muted-user-avatar {
            width: 40px;
            height: 40px;
            border: 3px solid $brutalist-text-dark;
            margin-right: 10px;
            object-fit: cover;
          }
          
          .muted-user-details {
            display: flex;
            flex-direction: column;
            
            .muted-user-name {
              font-weight: 800;
              font-size: 16px;
              text-transform: uppercase;
            }
            
            .muted-user-username {
              color: $brutalist-gray-600;
              font-size: 14px;
            }
          }
        }
        
        .unmute-btn {
          @include brutalist-button;
          padding: 6px 12px;
          font-size: 12px;
          margin-bottom: 0;
        }
      }
    }
  }
  
  .error-message {
    color: $brutalist-warning;
    background-color: rgba($brutalist-warning, 0.1);
    padding: 10px;
    border: 3px solid $brutalist-warning;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .no-comments-message {
    @include brutalist-container;
    text-align: center;
    padding: 40px 20px;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      width: 20px;
      height: 20px;
      background-color: $brutalist-primary;
      z-index: 2;
    }
    
    p {
      margin: 10px 0;
      
      &:first-child {
        font-weight: 900;
        font-size: 18px;
        text-transform: uppercase;
      }
    }
  }
  
  .comments-list {
    .comment-card {
      @include brutalist-card;
      margin-bottom: 20px;
      padding: 15px;
      
      .comment-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        border-bottom: 2px solid $brutalist-gray-200;
        padding-bottom: 8px;
        
        .comment-author-info {
          display: flex;
          align-items: center;
          
          .author-avatar {
            width: 40px;
            height: 40px;
            border: 3px solid $brutalist-text-dark;
            margin-right: 10px;
            object-fit: cover;
          }
          
          .author-details {
            display: flex;
            flex-direction: column;
            
            .author-name {
              font-weight: 800;
              font-size: 16px;
              text-transform: uppercase;
            }
            
            .comment-date {
              color: $brutalist-gray-600;
              font-size: 12px;
            }
          }
        }
        
        .mute-btn {
          @include brutalist-button;
          padding: 6px 12px;
          font-size: 12px;
          margin-bottom: 0;
        }
      }
      
      .article-info {
        margin-bottom: 10px;
        
        .article-title {
          margin: 0;
          font-size: 18px;
          
          a {
            @include brutalist-link;
            font-size: 16px;
          }
        }
      }
      
      .comment-content {
        margin-bottom: 15px;
        line-height: 1.5;
        font-size: 16px;
        padding: 10px;
        border-left: 4px solid $brutalist-primary;
        background-color: rgba($brutalist-primary, 0.05);
      }
      
      .comment-footer {
        text-align: right;
        
        .view-in-context-link {
          @include brutalist-link;
          font-size: 14px;
        }
      }
    }
    
    .load-more-container {
      text-align: center;
      margin-top: 20px;
      
      .load-more-btn {
        @include brutalist-button;
        padding: 10px 20px;
        font-size: 16px;
        
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
}

// Dark mode styles
body.dark-mode {
  .followers-feed-container {
    color: $brutalist-dark-text;
    
    .followers-feed-header {
      border-bottom-color: $brutalist-dark-border;
      
      .manage-muted-btn {
        background-color: $brutalist-dark-accent;
        border-color: $brutalist-dark-border;
        color: $brutalist-dark-text;
        
        &:hover {
          background-color: lighten($brutalist-dark-accent, 10%);
        }
      }
    }
    
    .muted-users-container {
      background-color: $brutalist-dark-surface;
      border-color: $brutalist-dark-border;
      
      .muted-user-item {
        border-bottom-color: $brutalist-dark-border;
        
        .muted-user-avatar {
          border-color: $brutalist-dark-border;
        }
        
        .muted-user-username {
          color: darken($brutalist-dark-text, 20%);
        }
        
        .unmute-btn {
          background-color: $brutalist-dark-accent;
          border-color: $brutalist-dark-border;
          color: $brutalist-dark-text;
          
          &:hover {
            background-color: lighten($brutalist-dark-accent, 10%);
          }
        }
      }
    }
    
    .error-message {
      background-color: rgba($brutalist-warning, 0.2);
      color: brutalist-lighten($brutalist-warning, 15%);
      border-color: $brutalist-warning;
    }
    
    .no-comments-message {
      background-color: $brutalist-dark-surface;
      border-color: $brutalist-dark-border;
      
      &::before {
        background-color: $brutalist-dark-accent;
      }
    }
    
    .comment-card {
      background-color: $brutalist-dark-surface;
      border-color: $brutalist-dark-border;
      
      &::before {
        background-color: $brutalist-dark-accent;
      }
      
      .comment-header {
        border-bottom-color: $brutalist-dark-border;
        
        .author-avatar {
          border-color: $brutalist-dark-border;
        }
        
        .comment-date {
          color: darken($brutalist-dark-text, 20%);
        }
        
        .mute-btn {
          background-color: $brutalist-dark-accent;
          border-color: $brutalist-dark-border;
          color: $brutalist-dark-text;
          
          &:hover {
            background-color: lighten($brutalist-dark-accent, 10%);
          }
        }
      }
      
      .article-title {
        a {
          color: $brutalist-dark-accent;
          
          &::after {
            background-color: $brutalist-dark-accent;
          }
        }
      }
      
      .comment-content {
        border-left-color: $brutalist-dark-accent;
        background-color: rgba($brutalist-dark-accent, 0.1);
      }
      
      .view-in-context-link {
        color: $brutalist-dark-accent;
        
        &::after {
          background-color: $brutalist-dark-accent;
        }
      }
    }
    
    .load-more-btn {
      background-color: $brutalist-dark-accent;
      border-color: $brutalist-dark-border;
      color: $brutalist-dark-text;
      
      &:hover {
        background-color: lighten($brutalist-dark-accent, 10%);
      }
    }
  }
}