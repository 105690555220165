@import '../../../styles/brutalist.scss';

.profile-container {
  background-color: $brutalist-bg-light;
  color: $brutalist-text-dark;
  min-height: 100vh;
  padding: 30px;
  position: relative;
  overflow: hidden;
  
  // Brutalist decorative elements
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background-color: $brutalist-primary;
    z-index: 1;
  }
}

.profile-header {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 0 40px 40px;
  display: flex;
  justify-content: space-between; // Changed from flex-start to accommodate mute button
  align-items: center;
  
  h1 {
    margin: 0;
    font-size: 2.2rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: -1px;
    transform: rotate(-1deg);
    padding: 5px 15px;
    background-color: $brutalist-primary;
    color: $brutalist-text-light;
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
  }
  
  .mute-user-container {
    margin-left: 20px;
    
    .mute-toggle-button {
      padding: 8px 16px;
      font-weight: 700;
      text-transform: uppercase;
      background-color: $brutalist-gray-900;
      border: 3px solid $brutalist-gray-900;
      color: $brutalist-text-light;
      cursor: pointer;
      transition: all 0.2s ease;
      
      &:hover {
        transform: translateY(-2px);
        box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.2);
      }
      
      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
      
      &.unmute-button {
        background-color: $brutalist-primary;
        border-color: $brutalist-primary;
        
        &:hover {
          background-color: darken($brutalist-primary, 10%);
        }
      }
      
      &.mute-button {
        background-color: $brutalist-gray-900;
        
        &:hover {
          background-color: $brutalist-warning;
        }
      }
    }
  }
}

.dark-mode-toggle {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 10;
}

.dark-mode-button {
  background-color: $brutalist-gray-900;
  border: 3px solid $brutalist-primary;
  color: $brutalist-text-light;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.3);
    background-color: $brutalist-primary;
  }
  
  &:focus {
    outline: none;
  }
}

.profile-back-button {
  position: absolute;
  left: 30px;
  top: 30px;
  background-color: $brutalist-gray-900;
  border: 3px solid $brutalist-primary;
  color: $brutalist-text-light;
  z-index: 10;
  
  &:hover {
    background-color: $brutalist-primary;
    transform: translateX(-3px);
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.3);
  }
}

.profile-content {
  max-width: 1000px;
  margin: 0 auto;
  
  .profile-section {
    @include brutalist-container;
    margin-bottom: 30px;
    position: relative;
    background-color: $brutalist-gray-100;
    border: 4px solid $brutalist-text-dark;
    padding: 30px;
    box-shadow: 8px 8px 0 rgba(0, 0, 0, 0.2);
    
    &::before {
      content: '';
      position: absolute;
      top: -15px;
      left: -15px;
      width: 30px;
      height: 30px;
      background-color: $brutalist-primary;
      z-index: 2;
    }
    
    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      border-bottom: 3px solid $brutalist-text-dark;
      padding-bottom: 10px;
      
      h2 {
        @include brutalist-section-title;
        margin: 0;
      }
      
      .toggle-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        color: $brutalist-text-dark;
        
        &:hover {
          color: $brutalist-primary;
        }
      }
    }
    
    .section-content {
      padding: 10px 0;
    }
  }
  
  // User Information Section
  .user-info-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    
    .profile-header-container {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
      
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
      
      .user-avatar {
        width: 150px;
        height: 150px;
        border: 4px solid $brutalist-text-dark;
        object-fit: cover;
        
        &:hover {
          border-color: $brutalist-primary;
        }
      }
      
      .user-activity-stats {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-self: center;
        
        @media (max-width: 768px) {
          width: 100%;
          max-width: 300px;
          margin-top: 10px;
        }
        
        .activity-stat-row {
          display: flex;
          gap: 8px;
          width: 100%;
          
          .activity-stat {
            flex: 1;
            text-align: center;
            padding: 6px 8px;
            border: 2px solid $brutalist-text-dark;
            background-color: $brutalist-gray-100;
            cursor: pointer;
            transition: transform 0.2s;
            font-family: inherit;
            
            // Reset button styles when used as a button
            &:hover {
              transform: translateY(-2px);
              
              .stat-value {
                color: darken($brutalist-primary, 10%);
              }
            }
            
            &:focus {
              outline: none;
              border-color: $brutalist-primary;
            }
            
            .stat-value {
              font-size: 1.3rem;
              font-weight: 800;
              color: $brutalist-primary;
            }
            
            .stat-label {
              font-size: 0.7rem;
              text-transform: uppercase;
              font-weight: 600;
            }
          }
        }
      }
    }
    
    .user-details {
      width: 100%;
      
      .user-info-main {
        margin-bottom: 15px;
        
        .display-name {
          font-size: 1.8rem;
          font-weight: 800;
          margin-bottom: 5px;
        }
        
        .username {
          font-size: 1.2rem;
          color: $brutalist-gray-600;
          margin-bottom: 0;
        }
      }
      
      .bio {
        margin-bottom: 15px;
        font-size: 1rem;
        line-height: 1.4;
        word-wrap: break-word;
      }
      
      .join-date {
        font-size: 0.9rem;
        color: $brutalist-gray-600;
      }
      
      .bio-edit {
        margin-bottom: 15px;
        width: 100%;
        
        label {
          display: block;
          font-weight: 600;
          margin-bottom: 5px;
        }
        
        .bio-textarea {
          width: 100%;
          max-width: 100%;
          padding: 10px;
          border: 3px solid $brutalist-text-dark;
          font-family: inherit;
          font-size: 1rem;
          resize: vertical;
          box-sizing: border-box;
          
          &:focus {
            outline: none;
            border-color: $brutalist-primary;
          }
        }
      }
      
      .social-links-container {
        margin-top: 20px;
        margin-bottom: 15px;
        
        h4 {
          font-size: 1.1rem;
          font-weight: 700;
          margin-bottom: 10px;
          border-bottom: 2px solid $brutalist-primary;
          padding-bottom: 5px;
          display: inline-block;
        }
        
        .social-links-list {
          display: flex;
          flex-direction: column;
          gap: 10px;
          
          .no-socials-message {
            font-style: italic;
            color: $brutalist-gray-600;
          }
          
          .social-link-item {
            display: flex;
            align-items: center;
            gap: 10px;
            
            .social-link-icon {
              font-size: 1.5rem;
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $brutalist-gray-200;
              border: 2px solid $brutalist-text-dark;
              color: $brutalist-text-dark;
            }
            
            .social-link {
              text-decoration: none;
              color: $brutalist-primary;
              font-weight: 600;
              
              &:hover {
                text-decoration: underline;
              }
            }
            
            .social-link-edit {
              display: flex;
              align-items: center;
              flex: 1;
              gap: 5px;
              flex-wrap: wrap;
              width: 100%;
              max-width: 100%;
              
              .social-link-base-url {
                font-size: 0.9rem;
                color: $brutalist-gray-600;
                white-space: nowrap;
              }
              
              .social-link-input {
                flex: 1;
                min-width: 100px;
                max-width: calc(100% - 50px);
                padding: 5px 10px;
                border: 2px solid $brutalist-text-dark;
                font-family: inherit;
                box-sizing: border-box;
                
                &:focus {
                  outline: none;
                  border-color: $brutalist-primary;
                }
              }
              
              .social-link-remove-btn {
                background: none;
                border: none;
                color: $brutalist-warning;
                cursor: pointer;
                font-size: 1rem;
                padding: 5px;
                
                &:hover {
                  color: darken($brutalist-warning, 10%);
                }
              }
            }
          }
          
          .add-social-links {
            margin-top: 10px;
            
            p {
              font-size: 0.9rem;
              font-weight: 600;
              margin-bottom: 5px;
            }
            
            .platform-buttons {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              
              .add-platform-btn {
                background: none;
                border: 2px solid $brutalist-text-dark;
                padding: 5px 10px;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 1.2rem;
                
                &:hover {
                  background-color: $brutalist-gray-200;
                }
                
                .add-icon {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }
      
      .error-message {
        color: $brutalist-warning;
        font-weight: 600;
        margin-top: 10px;
      }
      
      .profile-actions {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 15px;
        width: 100%;
        max-width: 100%;
        padding-right: 10px;
        box-sizing: border-box;
        
        .edit-profile-button,
        .save-profile-button,
        .cancel-edit-button {
          @include brutalist-button;
          min-width: 0;
          overflow: visible;
          white-space: nowrap;
        }
        
        .save-profile-button {
          background-color: $brutalist-primary;
          
          &:hover:not(:disabled) {
            background-color: darken($brutalist-primary, 10%);
          }
          
          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }
        
        .cancel-edit-button {
          background-color: $brutalist-gray-500;
          
          &:hover:not(:disabled) {
            background-color: darken($brutalist-gray-500, 10%);
          }
          
          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }
      }
    }
  }
  
  // User Activity Overview
  .activity-overview {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 15px;
    
    .activity-stat {
      text-align: center;
      padding: 15px;
      border: 3px solid $brutalist-text-dark;
      background-color: $brutalist-gray-100;
      
      .stat-value {
        font-size: 2rem;
        font-weight: 800;
        color: $brutalist-primary;
      }
      
      .stat-label {
        font-size: 0.9rem;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }
  
  // User Engagement & Content
  .engagement-tabs {
    margin-bottom: 20px;
    display: flex;
    border-bottom: 3px solid $brutalist-text-dark;
    
    .tab-button {
      background: none;
      border: 3px solid $brutalist-text-dark;
      border-bottom: none;
      padding: 10px 20px;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
      margin-right: 5px;
      
      &.active {
        background-color: $brutalist-primary;
        color: $brutalist-text-light;
      }
      
      &:hover:not(.active) {
        background-color: $brutalist-gray-200;
      }
    }
  }
  
  .engagement-content {
    min-height: 200px;
    
    .comment-item, .article-item, .liked-comment-item {
      border: 2px solid $brutalist-text-dark;
      margin-bottom: 15px;
      padding: 15px;
      background-color: $brutalist-gray-100;
      
      .item-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 0.9rem;
        color: $brutalist-gray-600;
      }
      
      .item-content {
        margin-bottom: 10px;
      }
      
      .item-footer {
        display: flex;
        justify-content: space-between;
        font-size: 0.9rem;
      }
    }
  }
  
  // Customization & Settings
  .settings-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    
    .setting-group {
      border: 2px solid $brutalist-text-dark;
      padding: 15px;
      
      h3 {
        margin-top: 0;
        margin-bottom: 15px;
        font-size: 1.2rem;
        font-weight: 700;
        text-transform: uppercase;
        border-bottom: 2px solid $brutalist-primary;
        padding-bottom: 5px;
      }
      
      .setting-item {
        margin-bottom: 15px;
        
        .setting-label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 5px;
          font-weight: 600;
        }
        
        .toggle-switch {
          position: relative;
          display: inline-block;
          width: 50px;
          height: 24px;
          
          input {
            opacity: 0;
            width: 0;
            height: 0;
            
            &:checked + .slider {
              background-color: $brutalist-primary;
            }
            
            &:checked + .slider:before {
              transform: translateX(26px);
            }
          }
          
          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $brutalist-gray-400;
            transition: .4s;
            border: 2px solid $brutalist-text-dark;
            
            &:before {
              position: absolute;
              content: "";
              height: 16px;
              width: 16px;
              left: 2px;
              bottom: 2px;
              background-color: $brutalist-text-light;
              transition: .4s;
              border: 1px solid $brutalist-text-dark;
            }
          }
        }
      }
    }
  }
  
  // Additional Features
  .badges-section {
    .badges-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      gap: 15px;
      
      .badge-item {
        text-align: center;
        padding: 10px;
        border: 2px solid $brutalist-text-dark;
        
        .badge-icon {
          width: 50px;
          height: 50px;
          margin: 0 auto 10px;
          object-fit: contain;
        }
        
        .badge-name {
          font-size: 0.9rem;
          font-weight: 700;
          margin-bottom: 5px;
        }
        
        .badge-date {
          font-size: 0.8rem;
          color: $brutalist-gray-600;
        }
      }
    }
    
    .reputation-score {
      margin-top: 20px;
      text-align: center;
      padding: 15px;
      border: 3px solid $brutalist-text-dark;
      background-color: $brutalist-gray-100;
      
      .score-value {
        font-size: 2.5rem;
        font-weight: 900;
        color: $brutalist-primary;
      }
      
      .score-label {
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }
  
  // Follow Button Styles
  .follow-button-container {
    margin-top: 15px;
    
    .follow-button {
      @include brutalist-button;
      
      &.following {
        background-color: $brutalist-text-dark;
        color: $brutalist-text-light;
        
        &:hover {
          background-color: $brutalist-warning;
        }
      }
    }
    
    .follow-error-message {
      margin-top: 8px;
      padding: 8px;
      background-color: rgba($brutalist-warning, 0.1);
      border-left: 4px solid $brutalist-warning;
      color: darken($brutalist-warning, 20%);
      font-size: 0.9rem;
      font-weight: 600;
    }
  }
  
  // Follow Modal Styles
  .brutalist-modal.follow-modal {
    .modal-content {
      @include brutalist-container;
      box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.8);
    }
    
    .modal-header {
      border-bottom: 4px solid $brutalist-text-dark;
      
      .modal-title {
        @include brutalist-section-title;
        margin-bottom: 0;
      }
      
      .close-button {
        padding: 5px 10px;
        font-size: 1rem;
      }
    }
    
    .modal-body {
      padding: 15px;
      
      .empty-list {
        text-align: center;
        font-weight: bold;
        padding: 20px;
      }
      
      .loading-container {
        text-align: center;
        padding: 20px;
        font-weight: bold;
      }
      
      .follow-list {
        max-height: 400px;
        overflow-y: auto;
        
        .follow-list-item {
          padding: 10px;
          margin-bottom: 10px;
          border: 2px solid $brutalist-text-dark;
          
          &:last-child {
            margin-bottom: 0;
          }
          
          .user-link {
            display: flex;
            align-items: center;
            gap: 15px;
            text-decoration: none;
            color: $brutalist-text-dark;
            
            &:hover {
              .user-name {
                color: $brutalist-primary;
              }
            }
            
            .user-avatar-container {
              flex-shrink: 0;
              
              .user-avatar-small {
                width: 50px;
                height: 50px;
                border: 2px solid $brutalist-text-dark;
                object-fit: cover;
              }
            }
            
            .user-info {
              flex: 1;
              
              .user-name {
                font-weight: 700;
                font-size: 1.1rem;
                margin-bottom: 2px;
              }
              
              .user-username {
                color: $brutalist-gray-600;
                font-size: 0.9rem;
                margin-bottom: 5px;
              }
              
              .follow-date {
                font-size: 0.8rem;
                color: $brutalist-gray-500;
              }
            }
          }
        }
      }
    }
  }
  
  .logout-button {
    @include brutalist-button;
    background-color: $brutalist-warning;
    margin-top: 20px;
  }
}

// Dark mode styles
.profile-container.dark-mode {
  background-color: $brutalist-dark-background;
  color: $brutalist-dark-text;
  
  &::before, &::after {
    border-color: $brutalist-dark-accent;
  }
  
  .profile-header h1 {
    background-color: $brutalist-dark-accent;
    color: $brutalist-dark-text;
  }
  
  .profile-header .mute-user-container .mute-toggle-button {
    background-color: $brutalist-dark-accent;
    border-color: $brutalist-dark-accent;
    color: $brutalist-dark-text;
    
    &:hover {
      box-shadow: 3px 3px 0 rgba(255, 255, 255, 0.2);
    }
    
    &.unmute-button {
      background-color: $brutalist-primary;
      border-color: $brutalist-primary;
    }
    
    &.mute-button {
      background-color: $brutalist-dark-accent;
    }
  }
  
  .dark-mode-button {
    background-color: $brutalist-dark-surface;
    border-color: $brutalist-dark-accent;
    color: $brutalist-dark-text;
    
    &:hover {
      background-color: $brutalist-dark-accent;
    }
  }
  
  .profile-back-button {
    background-color: $brutalist-dark-surface;
    border-color: $brutalist-dark-accent;
    color: $brutalist-dark-text;
    
    &:hover {
      background-color: $brutalist-dark-accent;
    }
  }
    
  .profile-content .profile-section {
      background-color: $brutalist-dark-surface;
      border-color: $brutalist-dark-border;
      
      &::before {
        background-color: $brutalist-dark-accent;
      }
      
      .section-header {
        border-bottom-color: $brutalist-dark-border;
        
        h2 {
          color: $brutalist-dark-text;
          
          &::before {
            background-color: $brutalist-dark-accent;
          }
        }
        
        .toggle-button {
          color: $brutalist-dark-text;
          
          &:hover {
            color: $brutalist-dark-accent;
          }
        }
      }
    }
    
    .user-info-section {
      .profile-header-container {
        .user-avatar {
          border-color: $brutalist-dark-border;
          
          &:hover {
            border-color: $brutalist-dark-accent;
          }
        }
        
        .user-activity-stats {
          .activity-stat-row {
            .activity-stat {
              background-color: $brutalist-dark-surface;
              border-color: $brutalist-dark-border;
              
              .stat-value {
                color: $brutalist-dark-accent;
              }
              
              .stat-label {
                color: $brutalist-dark-text;
              }
            }
          }
        }
      }
      
      .user-details {
        .user-info-main {
          .username {
            color: $brutalist-gray-400;
          }
        }
        
        .username {
          color: $brutalist-gray-400;
        }
        
        .join-date {
          color: $brutalist-gray-400;
        }
        
        .bio-edit {
          .bio-textarea {
            background-color: $brutalist-dark-surface;
            border-color: $brutalist-dark-border;
            color: $brutalist-dark-text;
            
            &:focus {
              border-color: $brutalist-dark-accent;
            }
          }
        }
        
        .social-links-container {
          h4 {
            border-bottom-color: $brutalist-dark-accent;
          }
          
          .social-links-list {
            .no-socials-message {
              color: $brutalist-gray-400;
            }
            
            .social-link-item {
              .social-link-icon {
                background-color: $brutalist-dark-surface;
                border-color: $brutalist-dark-border;
                color: $brutalist-dark-text;
              }
              
              .social-link {
                color: $brutalist-dark-accent;
              }
              
              .social-link-edit {
                .social-link-base-url {
                  color: $brutalist-gray-400;
                }
                
                .social-link-input {
                  background-color: $brutalist-dark-surface;
                  border-color: $brutalist-dark-border;
                  color: $brutalist-dark-text;
                  
                  &:focus {
                    border-color: $brutalist-dark-accent;
                  }
                }
              }
            }
            
            .add-social-links {
              .platform-buttons {
                .add-platform-btn {
                  background-color: $brutalist-dark-surface;
                  border-color: $brutalist-dark-border;
                  color: $brutalist-dark-text;
                  
                  &:hover {
                    background-color: lighten($brutalist-dark-surface, 10%);
                  }
                }
              }
            }
          }
        }
        
        .profile-actions {
          .edit-profile-button,
          .save-profile-button,
          .cancel-edit-button {
            background-color: $brutalist-dark-accent;
            border-color: $brutalist-dark-border;
            color: $brutalist-dark-text;
          }
          
          .save-profile-button {
            background-color: $brutalist-primary;
            
            &:hover:not(:disabled) {
              background-color: darken($brutalist-primary, 10%);
            }
          }
          
          .cancel-edit-button {
            background-color: $brutalist-gray-700;
            
            &:hover:not(:disabled) {
              background-color: darken($brutalist-gray-700, 10%);
            }
          }
        }
      }
    }
    
    .activity-overview {
      .activity-stat {
        background-color: $brutalist-dark-surface;
        border-color: $brutalist-dark-border;
        
        .stat-value {
          color: $brutalist-dark-accent;
        }
        
        .stat-label {
          color: $brutalist-dark-text;
        }
      }
    }
    
    .engagement-tabs {
      border-bottom-color: $brutalist-dark-border;
      
      .tab-button {
        border-color: $brutalist-dark-border;
        color: $brutalist-dark-text;
        
        &.active {
          background-color: $brutalist-dark-accent;
        }
        
        &:hover:not(.active) {
          background-color: $brutalist-dark-surface;
        }
      }
    }
    
    .engagement-content {
      .comment-item, .article-item, .liked-comment-item {
        background-color: $brutalist-dark-surface;
        border-color: $brutalist-dark-border;
        
        .item-header {
          color: $brutalist-gray-400;
        }
      }
    }
    
    .settings-grid {
      .setting-group {
        border-color: $brutalist-dark-border;
        
        h3 {
          border-bottom-color: $brutalist-dark-accent;
        }
        
        .setting-item {
          .toggle-switch {
            .slider {
              background-color: $brutalist-gray-700;
              border-color: $brutalist-dark-border;
              
              &:before {
                background-color: $brutalist-dark-text;
                border-color: $brutalist-dark-border;
              }
            }
            
            input:checked + .slider {
              background-color: $brutalist-dark-accent;
            }
          }
        }
      }
    }
    
    .badges-section {
      .badges-grid {
        .badge-item {
          border-color: $brutalist-dark-border;
          
          .badge-date {
            color: $brutalist-gray-400;
          }
        }
      }
      
      .reputation-score {
        background-color: $brutalist-dark-surface;
        border-color: $brutalist-dark-border;
        
        .score-value {
          color: $brutalist-dark-accent;
        }
      }
    }
    
    .follow-button {
      background-color: $brutalist-dark-accent;
      border-color: $brutalist-dark-border;
      color: $brutalist-dark-text;
      
      &:hover {
        background-color: lighten($brutalist-dark-accent, 10%);
      }
    }
    
    .logout-button {
      background-color: $brutalist-warning;
      border-color: $brutalist-dark-border;
      color: $brutalist-dark-text;
      
      &:hover {
        background-color: lighten($brutalist-warning, 10%);
      }
    }
}