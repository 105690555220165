@import '../../../styles/brutalist.scss';

.feed-container {
  padding: 2rem 1rem;
  background-color: $brutalist-bg-light; // Consistent with dashboard
  min-height: 100vh;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10px;
    height: 10px;
    background-color: $brutalist-primary;
    z-index: 0;
  }
}

/* Dark mode overrides */
.dark-mode .feed-container {
  background-color: $brutalist-dark-background;
  color: $brutalist-dark-text;
  
  &::after {
    background-color: $brutalist-dark-accent;
  }
}

.feed-title {
  margin-bottom: 2.5rem;
  font-size: 1.8rem;
  font-weight: 800;
  display: inline-block;
  position: relative;
  margin-left: 10px;
  color: $brutalist-gray-900;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid $brutalist-primary;
}

/* Dark mode overrides for feed title */
.dark-mode .feed-title {
  color: $brutalist-dark-text;
  border-bottom-color: $brutalist-dark-accent;
}

.brutalist-feed-card {
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  padding: 1rem;
  background-color: $brutalist-gray-100; // Slightly darker than the dashboard background
  border: 1px solid $brutalist-gray-200;
  border-left: none;
  border-right: none;
  
  &:hover:not(.expanded) {
    transform: translateY(-3px);
    border-bottom: 2px solid $brutalist-primary;
    box-shadow: 0 5px 15px brutalist-rgba($brutalist-text-dark, 0.1);
  }
  
  &:nth-child(odd) {
    border-left: 3px solid $brutalist-primary;
  }
  
  &:nth-child(even) {
    border-right: 3px solid $brutalist-primary;
  }
  
  // Indicator for collapsed cards
  &:not(.expanded)::after {
    content: '+';
    display: block;
    font-size: 1.5rem;
    text-align: center;
    color: $brutalist-primary;
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  &:hover:not(.expanded)::after {
    opacity: 1;
  }
  
  // Expanded state
  &.expanded {
    background-color: brutalist-darken($brutalist-gray-100, 5%); // Even darker when expanded
    border-top: 2px solid $brutalist-primary;
    border-bottom: 2px solid $brutalist-primary;
    box-shadow: 0 8px 20px brutalist-rgba($brutalist-text-dark, 0.1);
    transform: translateY(-2px);
    
    &::after {
      content: '−';
      display: block;
      font-size: 1.5rem;
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      color: $brutalist-primary;
    }
  }
}

/* Dark mode overrides for feed cards */
.dark-mode .brutalist-feed-card {
  background-color: $brutalist-dark-surface;
  border-color: $brutalist-dark-border;
  
  &:hover:not(.expanded) {
    border-bottom-color: $brutalist-dark-accent;
    box-shadow: 0 5px 15px brutalist-rgba($brutalist-dark-border, 0.3);
  }
  
  &:nth-child(odd) {
    border-left-color: $brutalist-dark-accent;
  }
  
  &:nth-child(even) {
    border-right-color: $brutalist-dark-accent;
  }
  
  &:not(.expanded)::after {
    color: $brutalist-dark-accent;
  }
  
  &.expanded {
    background-color: darken($brutalist-dark-surface, 3%);
    border-top-color: $brutalist-dark-accent;
    border-bottom-color: $brutalist-dark-accent;
    box-shadow: 0 8px 20px brutalist-rgba(black, 0.2);
    
    &::after {
      color: $brutalist-dark-accent;
    }
  }
}

.brutalist-card-title {
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -0.5px;
  font-size: 1.4rem;
  margin: 0.5rem 0;
  line-height: 1.2;
  padding: 0.75rem 1rem;
  display: block;
  color: $brutalist-gray-900;
  text-align: left;
  transition: all 0.3s ease;
  
  &::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: $brutalist-primary;
    margin-right: 0.7rem;
  }
  
  .expanded & {
    font-size: 1.6rem;
    color: $brutalist-primary;
    border-bottom: 1px solid brutalist-rgba($brutalist-text-dark, 0.1);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    
    &::before {
      width: 15px;
      height: 15px;
    }
  }
}

/* Dark mode overrides for card title */
.dark-mode .brutalist-card-title {
  color: $brutalist-dark-text;
  
  &::before {
    background-color: $brutalist-dark-accent;
  }
  
  .expanded & {
    color: $brutalist-dark-accent;
    border-bottom-color: brutalist-rgba($brutalist-dark-text, 0.1);
  }
}

.brutalist-card-content {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  color: $brutalist-gray-800;
  padding: 0;
  flex: 1;
  min-width: 0; // Fix for flex items with long words
  
  // Set min height to match the image height
  min-height: 200px;
  
  @media (max-width: 768px) {
    min-height: auto;
    padding: 0 0.5rem;
  }
}

/* Dark mode overrides for card content */
.dark-mode .brutalist-card-content {
  color: darken($brutalist-dark-text, 10%);
}

.brutalist-card-meta {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  color: $brutalist-gray-500;
  padding: 0.75rem 0 0;
  margin-top: 0.75rem;
  border-top: 1px solid brutalist-rgba($brutalist-text-dark, 0.1);
  display: flex;
  justify-content: space-between;
  
  .author {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $brutalist-primary;
      opacity: 0.5;
    }
  }
}

/* Dark mode overrides for card meta */
.dark-mode .brutalist-card-meta {
  color: darken($brutalist-dark-text, 15%);
  border-top-color: brutalist-rgba($brutalist-dark-text, 0.1);
  
  .author::after {
    background-color: $brutalist-dark-accent;
  }
}

.brutalist-card-flex-container {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: flex-start;
  margin-bottom: 1rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.brutalist-card-thumbnail {
  overflow: hidden;
  position: relative;
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transform: scale(1);
    transition: transform 0.5s ease;
    
    &:hover {
      transform: scale(1.02);
    }
  }
  
  &.expanded {
    margin: 0;
    border: 2px solid $brutalist-primary;
    
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 20%;
      height: 4px;
      background-color: $brutalist-primary;
    }
  }
  
  .dark-mode & {
    &.expanded {
      border-color: $brutalist-dark-accent;
      
      &::after {
        background-color: $brutalist-dark-accent;
      }
    }
  }
  
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
    
    img {
      height: auto;
      aspect-ratio: 1/1;
    }
  }
}

.brutalist-card-no-thumbnail {
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  background-color: $brutalist-gray-200;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 2px solid $brutalist-primary;
  
  .brutalist-card-placeholder {
    height: 50px;
    width: 50px;
    background-color: $brutalist-primary;
  }
  
  &::after {
    content: 'NO IMAGE';
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 0.7rem;
    font-weight: bold;
    color: $brutalist-gray-500;
  }
  
  .dark-mode & {
    background-color: $brutalist-dark-surface;
    border-color: $brutalist-dark-accent;
    
    .brutalist-card-placeholder {
      background-color: $brutalist-dark-accent;
    }
    
    &::after {
      color: $brutalist-dark-text;
    }
  }
  
  @media (max-width: 768px) {
    width: 100%;
    height: 200px;
  }
}

.brutalist-card-expanded-content {
  margin-top: 0;
  animation: fadeIn 0.3s ease-in-out;
  padding: 1rem;
  background-color: brutalist-rgba($brutalist-gray-100, 0.5);
  border-top: 1px solid $brutalist-gray-200;
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .dark-mode & {
    background-color: brutalist-rgba($brutalist-dark-surface, 0.7);
    border-top-color: $brutalist-dark-border;
  }
  
  @media (max-width: 768px) {
    padding: 0.75rem;
  }
}

.brutalist-view-full-details {
  display: block;
  margin: 1.5rem 0 0.5rem;
  background-color: transparent;
  color: $brutalist-primary;
  border: 1px solid $brutalist-primary;
  padding: 0.6rem 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.75rem;
  transition: all 0.2s ease;
  width: fit-content;
  
  &:hover {
    background-color: $brutalist-primary;
    color: $brutalist-text-light;
    transform: translateY(-2px);
  }
  
  &:active {
    transform: translateY(0);
  }
  
  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: $brutalist-primary;
    margin-right: 0.5rem;
    transition: background-color 0.2s ease;
  }
  
  &:hover::before {
    background-color: $brutalist-text-light;
  }
}

/* Dark mode overrides for view details button */
.dark-mode .brutalist-view-full-details {
  color: $brutalist-dark-accent;
  border-color: $brutalist-dark-accent;
  
  &:hover {
    background-color: $brutalist-dark-accent;
    color: $brutalist-dark-text;
  }
  
  &::before {
    background-color: $brutalist-dark-accent;
  }
  
  &:hover::before {
    background-color: $brutalist-dark-text;
  }
}

.feed-empty {
  padding: 3rem 1rem;
  text-align: center;
  background-color: $brutalist-gray-100;
  border-left: 3px solid $brutalist-primary;
  border-right: 3px solid $brutalist-primary;
  margin: 2rem 0;
  
  h3 {
    font-weight: 800;
    font-size: 1.5rem;
    color: $brutalist-gray-900;
    text-transform: uppercase;
    margin-bottom: 1rem;
    letter-spacing: 1px;
  }
  
  p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    color: $brutalist-gray-600;
  }
}

/* Dark mode overrides for empty feed */
.dark-mode .feed-empty {
  background-color: $brutalist-dark-surface;
  border-left-color: $brutalist-dark-accent;
  border-right-color: $brutalist-dark-accent;
  
  h3 {
    color: $brutalist-dark-text;
  }
  
  p {
    color: darken($brutalist-dark-text, 15%);
  }
}

// Styles for the View More / View Less toggle
.brutalist-view-more-less {
  background-color: transparent;
  border: none;
  outline: none; // Remove focus outline
  padding: 0.5rem 0; // Adjust spacing
  margin-top: 0.5rem; // Space below bullets
  font-weight: bold;
  color: $brutalist-primary;
  cursor: pointer;
  text-transform: uppercase; // Optional: match other buttons
  letter-spacing: 1px; // Optional
  font-size: 0.9rem; // Adjust size as needed
  transition: color 0.2s ease;

  &:hover {
    color: darken($brutalist-primary, 10%); // Subtle hover effect
  }

  .dark-mode & {
    color: $brutalist-dark-accent;

    &:hover {
      color: lighten($brutalist-dark-accent, 10%);
    }
  }
}

// Styles for the moved main content block
.brutalist-main-content {
  width: 100%; // Ensure it takes full width below the flex container
  margin-top: 1rem; // Add space above
  padding: 0.5rem 0; // Add some vertical padding
  font-size: 1rem;
  line-height: 1.5;
  color: $brutalist-gray-800;

  .dark-mode & {
    color: darken($brutalist-dark-text, 10%);
  }
}

// Loading state
.brutalist-loading {
  padding: 3rem 1rem;
  text-align: center;
  position: relative;
  margin-top: 2rem;
  background-color: $brutalist-gray-100;
  
  h3 {
    font-weight: 800;
    font-size: 1.5rem;
    color: $brutalist-gray-900;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 1.5rem;
  }
  
  .loading-box {
    width: 40px;
    height: 40px;
    background-color: $brutalist-primary;
    margin: 0 auto 1.5rem;
    animation: loading-pulse 1.5s infinite alternate;
  }
  
  @keyframes loading-pulse {
    0% {
      transform: scale(1) rotate(0deg);
      background-color: $brutalist-primary;
    }
    100% {
      transform: scale(1.2) rotate(90deg);
      opacity: 0.7;
    }
  }
}

/* Dark mode overrides for loading state */
.dark-mode .brutalist-loading {
  background-color: $brutalist-dark-surface;
  
  h3 {
    color: $brutalist-dark-text;
  }
  
  .loading-box {
    background-color: $brutalist-dark-accent;
    
    @keyframes loading-pulse {
      0% {
        transform: scale(1) rotate(0deg);
        background-color: $brutalist-dark-accent;
      }
      100% {
        transform: scale(1.2) rotate(90deg);
        opacity: 0.7;
      }
    }
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .feed-container {
    padding: 1.5rem 1rem;
  }
  
  .brutalist-feed-card {
    margin-bottom: 1.5rem;
  }
  
  .brutalist-card-title {
    font-size: 1.2rem;
  }
}
