@import '../../../styles/brutalist.scss';

.event-display {
  // Dark mode styles
  #post-login-container.dark-mode & {
    &__wrapper {
      background-color: $brutalist-dark-background;
    }

    &__carousel-container {
      background-color: $brutalist-dark-surface;
      border-color: $brutalist-dark-border;
      
      &::before {
        background-color: $brutalist-dark-accent;
      }
    }

    &__carousel-item {
      p {
        color: $brutalist-dark-text;
        
        &.perspective-label {
          color: $brutalist-dark-accent;
          border-bottom-color: $brutalist-dark-accent;
        }
      }
    }

    &__back-button,
    &__up-button,
    &__down-button {
      background-color: transparent;
      border-color: $brutalist-dark-border;
      color: $brutalist-dark-text;
      
      &:hover {
        background-color: $brutalist-dark-accent;
      }
    }

    &__nav-button {
      color: $brutalist-dark-text;
      
      &:hover {
        color: $brutalist-dark-accent;
      }
    }

    &__comments {
      background-color: $brutalist-dark-surface;
      border-color: $brutalist-dark-border;
      
      &::after {
        background-color: $brutalist-dark-accent;
      }
    }
    
    &__comments-header {
      h2 {
        color: $brutalist-dark-text;
        border-bottom-color: $brutalist-dark-border;
      }
    }
  }

  &__wrapper {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $brutalist-bg-light;
    display: flex;
    flex-direction: column;
    
    @media (max-width: 480px) {
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
    }
  }
  
  &__content-section {
    flex: 0 0 auto;
    margin-bottom: 30px;
  }
  
  &__comments-section {
    flex: 0 0 auto;
    width: 100%;
    padding: 0 20px 40px 20px;
  }
  
  &__comments-header {
    text-align: center;
    margin-bottom: 20px;
    
    h2 {
      display: inline-block;
      font-size: 1.5rem;
      font-weight: 800;
      text-transform: uppercase;
      padding-bottom: 5px;
      border-bottom: 3px solid $brutalist-text-dark;
      color: $brutalist-text-dark;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 70vh;
    position: relative;

    // Add this media query
    @media (max-width: 768px) {
      padding-top: 60px; // Push container down on mobile
    }
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    position: relative;
  }

  &__back-button {
    background-color: transparent;
    border: 2px solid $brutalist-text-dark;
    color: $brutalist-text-dark;
    font-weight: 800;
    padding: 5px 10px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1000;
    transition: transform 0.2s, background-color 0.2s;
    
    &:hover {
      transform: translateY(-2px);
      background-color: $brutalist-primary;
    }
  }

  &__up-button {
    background-color: transparent;
    border: 2px solid $brutalist-text-dark;
    color: $brutalist-text-dark;
    font-weight: 800;
    padding: 5px 10px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    transition: transform 0.2s, background-color 0.2s;
    
    &:hover {
      transform: translateX(-50%) translateY(-2px);
      background-color: $brutalist-primary;
    }
  }

  &__carousel-container {
    position: relative;
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
    padding: 30px 20px;
    border: 3px solid $brutalist-text-dark;
    background-color: $brutalist-text-light;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    // Brutalist accent element
    &::before {
      content: '';
      position: absolute;
      top: -8px;
      left: -8px;
      width: 16px;
      height: 16px;
      background-color: $brutalist-primary;
    }
    
    // Mobile optimization
    @media (max-width: 768px) {
      width: 90%;
      padding: 20px 15px;
      min-height: 250px;
      
      .carousel-root {
        width: 100%;
      }
    }
    
    @media (max-width: 480px) {
      width: 95%;
      padding: 15px 10px;
      min-height: 200px;
      
      .carousel-root {
        width: 100%;
      }
      
      .carousel .slide {
        text-align: center;
      }
    }
  }

  &__carousel-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 0;
    text-align: left;
    min-height: 200px;
    width: 100%;

    p {
      font-size: 1.1rem;
      margin-bottom: 20px;
      line-height: 1.5;
      width: 100%;
      
      &.perspective-label {
        font-weight: 800;
        text-transform: uppercase;
        color: $brutalist-primary;
        letter-spacing: 1px;
        margin-top: 15px;
        padding-bottom: 3px;
        border-bottom: 2px solid $brutalist-primary;
        align-self: flex-start;
        display: inline-block;
      }
    }
    
    // Mobile optimization
    @media (max-width: 768px) {
      padding: 15px 5px;
      min-height: 180px;
      text-align: center;
      
      p {
        font-size: 1rem;
        margin-bottom: 15px;
        text-align: center;
      }
    }
    
    @media (max-width: 480px) {
      padding: 10px 5px;
      min-height: 150px;
      align-items: center;
      
      p {
        font-size: 0.9rem;
        margin-bottom: 10px;
        text-align: center;
        
        &.perspective-label {
          font-size: 0.8rem;
          align-self: center;
          text-align: center;
        }
      }
    }
  }

  &__nav-button {
    background: none;
    border: none;
    color: $brutalist-text-dark;
    font-size: 1.2rem;
    padding: 5px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1001;
    transition: color 0.2s;
    
    &:hover {
      color: $brutalist-primary;
    }
    
    &--left {
      left: -30px;
    }
    
    &--right {
      right: -30px;
    }
    
    // Mobile optimization
    @media (max-width: 768px) {
      font-size: 1rem;
      
      &--left {
        left: -25px;
      }
      
      &--right {
        right: -25px;
      }
    }
    
    @media (max-width: 480px) {
      font-size: 0.9rem;
      
      &--left {
        left: -15px;
      }
      
      &--right {
        right: -15px;
      }
    }
  }

  &__down-button {
    background-color: transparent;
    border: 2px solid $brutalist-text-dark;
    color: $brutalist-text-dark;
    padding: 8px 15px;
    cursor: pointer;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.2s, background-color 0.2s;
    
    .labeled-arrow-text {
      margin-bottom: 5px;
      font-weight: 800;
      text-transform: uppercase;
      font-size: 0.9rem;
      letter-spacing: 1px;
    }
    
    &:hover {
      transform: translateX(-50%) translateY(-2px);
      background-color: $brutalist-primary;
    }
    
    // Mobile optimization
    @media (max-width: 768px) {
      bottom: 5%;
      padding: 6px 12px;
      
      .labeled-arrow-text {
        font-size: 0.8rem;
      }
    }
    
    @media (max-width: 480px) {
      bottom: 3%;
      padding: 5px 10px;
      
      .labeled-arrow-text {
        font-size: 0.7rem;
      }
    }
  }

  &__tooltip {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
  }

  &__comments {
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    border: 3px solid $brutalist-text-dark;
    background-color: $brutalist-text-light;
    display: block; /* Force display as block */
    z-index: 10; /* Ensure it's on top */
    min-height: 400px; /* Minimum height to ensure visibility */
    box-shadow: 8px 8px 0 rgba(0, 0, 0, 0.1); /* Add shadow for depth */
    
    // Brutalist accent element
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -8px;
      right: -8px;
      width: 16px;
      height: 16px;
      background-color: $brutalist-primary;
    }
    
    // Make sure the comment system inside is visible
    .brutalist-comment-system {
      display: block !important;
      visibility: visible !important;
      height: auto !important;
      min-height: 200px !important;
      overflow: visible !important;
    }

    // Mobile optimization for padding
    @media (max-width: 600px) {
      padding: 30px 20px; // Reduce horizontal padding on smaller screens
      width: 100%; // Slightly increase width to compensate for reduced padding if needed
    }
  }
}

// Styles for the new 'event' perspective layout
.event-display__event-layout {
  display: flex;
  flex-direction: column;
  align-items: center; // Center content horizontally within the carousel item
  width: 100%;
  padding: 1rem; // Add some padding
  box-sizing: border-box;
}

.event-display__event-flex-container {
  display: flex;
  width: 100%;
  gap: 1rem; // Space between image and content
  margin-bottom: 1rem; // Space below the flex container
}

.event-display__event-thumbnail,
.event-display__event-no-thumbnail {
  flex-shrink: 0; // Prevent image container from shrinking
  width: 180px; // Increased width
  height: 180px; // Increased height
  border: 2px solid $brutalist-text-dark; // Changed border to black

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; // Ensure image covers the area
  }
}

.event-display__event-no-thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--brutalist-background-secondary); // Placeholder background
}

.event-display__event-placeholder {
  width: 50px;
  height: 50px;
  background-color: var(--brutalist-primary-color); // Placeholder element
}

.event-display__event-content {
  flex-grow: 1; // Allow content area to take remaining space
  display: flex;
  flex-direction: column;
}

.event-display__event-bullet-points {
  list-style: disc; // Standard bullet points
  padding-left: 20px; // Indent bullet points
  margin-bottom: 0.5rem; // Space below bullets
  text-align: left; // Align text left

  li {
    margin-bottom: 0.25rem; // Space between bullet points
  }
}

// Use existing brutalist button style, add margin if needed
.event-display__view-more-less {
  align-self: flex-start; // Align button to the left
  margin-top: auto; // Push button to the bottom if space allows
  margin-bottom: 0.5rem;
}

.event-display__event-main-content {
  width: 100%; // Take full width
  text-align: left; // Align text left
  margin-top: 0.5rem; // Space above main content
  border-top: 1px solid var(--brutalist-border-color); // Separator line
  padding-top: 0.5rem;
}

// Ensure perspective label is below the new content
.event-display__carousel-item .perspective-label {
  margin-top: 1rem; // Add space above the label
}
