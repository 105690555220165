@import '../../../styles/brutalist.scss';

.archive-content {
  background-color: $brutalist-bg-light;
  min-height: 95vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    background-color: $brutalist-primary;
  }
}

/* Dark mode overrides */
.dark-mode .archive-content {
  background-color: $brutalist-dark-background;
  color: $brutalist-dark-text;
  
  &::before {
    background-color: $brutalist-dark-accent;
  }
}

// Brutalist calendar
.brutalist-calendar {
  border: 3px solid $brutalist-background;
  background-color: $brutalist-text-light;
  width: 350px;
  max-width: 100%;
  font-family: inherit;
  
  .react-calendar__navigation {
    background-color: $brutalist-background;
    color: $brutalist-text-light;
    height: 40px;
    display: flex;
    
    button {
      color: $brutalist-text-light;
      font-weight: 700;
      min-width: 30px;
      background: none;
      border: none;
      font-size: 1.1rem;
      text-transform: uppercase;
      
      &:hover, &:focus {
        background-color: brutalist-rgba($brutalist-text-light, 0.1);
      }
      
      &:disabled {
        opacity: 0.5;
      }
    }
  }
  
  .react-calendar__month-view__weekdays {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 0.7rem;
    letter-spacing: 0.5px;
    color: $brutalist-primary;
    margin-top: 5px;
    
    abbr {
      text-decoration: none;
    }
  }
  
  .react-calendar__tile {
    height: 40px;
    font-weight: 600;
    position: relative;
    border: 1px solid $brutalist-gray-100;
    
    &:hover {
      background-color: brutalist-lighten($brutalist-gray-100, 3%);
    }
    
    &--active {
      background-color: $brutalist-primary !important;
      color: $brutalist-text-light;
    }
    
    &--now {
      border: 2px solid $brutalist-primary;
      
      &::before {
        content: '';
        position: absolute;
        top: -5px;
        right: -5px;
        width: 8px;
        height: 8px;
        background-color: $brutalist-background;
      }
    }
  }
}

/* Dark mode overrides for calendar */
.dark-mode .brutalist-calendar {
  border-color: $brutalist-dark-border;
  background-color: $brutalist-dark-surface;
  
  .react-calendar__navigation {
    background-color: $brutalist-dark-border;
    
    button {
      color: $brutalist-dark-text;
      
      &:hover, &:focus {
        background-color: brutalist-rgba($brutalist-dark-text, 0.1);
      }
    }
  }
  
  .react-calendar__month-view__weekdays {
    color: $brutalist-dark-accent;
  }
  
  .react-calendar__tile {
    border-color: darken($brutalist-dark-surface, 5%);
    color: $brutalist-dark-text;
    
    &:hover {
      background-color: darken($brutalist-dark-surface, 3%);
    }
    
    &--active {
      background-color: $brutalist-dark-accent !important;
      color: $brutalist-dark-text;
    }
    
    &--now {
      border-color: $brutalist-dark-accent;
      
      &::before {
        background-color: $brutalist-dark-border;
      }
    }
  }
}

// Brutalist carousel
.brutalist-carousel-container {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  
  .carousel {
    width: 80%;
    
    .slider-wrapper {
      border: 3px solid $brutalist-background;
      background-color: $brutalist-text-light;
      padding: 1.5rem;
    }
    
    .carousel-item {
      padding-bottom: 2rem;
    }
  }
  
  .carousel-arrow {
    background: none;
    border: 3px solid $brutalist-background;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s;
    
    &:hover:not(:disabled) {
      background-color: $brutalist-background;
      color: $brutalist-text-light;
      transform: scale(1.1);
    }
    
    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
}

/* Dark mode overrides for carousel */
.dark-mode .brutalist-carousel-container {
  .carousel {
    .slider-wrapper {
      border-color: $brutalist-dark-border;
      background-color: $brutalist-dark-surface;
    }
  }
  
  .carousel-arrow {
    border-color: $brutalist-dark-border;
    color: $brutalist-dark-text;
    
    &:hover:not(:disabled) {
      background-color: $brutalist-dark-border;
      color: $brutalist-dark-text;
    }
  }
}

// Loading and error states
.brutalist-loading {
  @include brutalist-container;
  padding: 2rem;
  margin-top: 2rem;
  text-align: center;
  
  .loading-indicator {
    width: 40px;
    height: 40px;
    background-color: $brutalist-primary;
    margin: 0 auto 1rem;
    animation: loading-pulse 1.5s infinite alternate;
  }
  
  @keyframes loading-pulse {
    0% {
      transform: scale(1) rotate(0deg);
    }
    100% {
      transform: scale(1.1) rotate(90deg);
      background-color: $brutalist-background;
    }
  }
}

/* Dark mode overrides for loading */
.dark-mode .brutalist-loading {
  background-color: $brutalist-dark-surface;
  border-color: $brutalist-dark-border;
  
  .loading-indicator {
    background-color: $brutalist-dark-accent;
    
    @keyframes loading-pulse {
      0% {
        transform: scale(1) rotate(0deg);
      }
      100% {
        transform: scale(1.1) rotate(90deg);
        background-color: $brutalist-dark-border;
      }
    }
  }
}

.brutalist-error {
  @include brutalist-container;
  padding: 2rem;
  margin-top: 2rem;
  border-color: $brutalist-warning;
  text-align: center;
  
  h3 {
    color: $brutalist-warning;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-weight: 700;
  }
}

/* Dark mode overrides for error */
.dark-mode .brutalist-error {
  background-color: $brutalist-dark-surface;
  border-color: $brutalist-warning;
  
  h3 {
    color: lighten($brutalist-warning, 10%);
  }
}
