@import '../../../../styles/brutalist.scss';

.main-content {
  background-color: $brutalist-bg-light;
  min-height: 95vh;
  overflow: auto;
  position: relative;
  padding: 1rem 0.5rem;
}

/* Dark mode overrides */
.dark-mode .main-content {
  background-color: $brutalist-dark-background;
  color: $brutalist-dark-text;
}

// Brutalist tabs
.brutalist-tabs {  
  .nav-item {
    margin-right: 5px;
    
    .nav-link {
      background-color: transparent;
      border: 2px solid $brutalist-background;
      border-bottom: none;
      color: $brutalist-background;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      padding: 0.5rem 1.2rem;
      border-radius: 0;
      position: relative;
      margin-bottom: -2px;
      
      &:hover {
        background-color: brutalist-rgba($brutalist-text-dark, 0.05);
      }
      
      &.active {
        background-color: $brutalist-text-light;
        color: $brutalist-primary;
        border-bottom: none;
        z-index: 1;
        
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: $brutalist-text-light;
        }
        
        &::before {
          content: '';
          position: absolute;
          top: -7px;
          right: -7px;
          width: 14px;
          height: 14px;
          background-color: $brutalist-primary;
          z-index: -1;
        }
      }
    }
  }
}

/* Dark mode overrides for tabs */
.dark-mode .brutalist-tabs {
  .nav-item {
    .nav-link {
      border-color: $brutalist-dark-border;
      color: $brutalist-dark-text;
      
      &:hover {
        background-color: brutalist-rgba($brutalist-dark-text, 0.1);
      }
      
      &.active {
        background-color: $brutalist-dark-surface;
        color: $brutalist-dark-accent;
        
        &::after {
          background-color: $brutalist-dark-surface;
        }
        
        &::before {
          background-color: $brutalist-dark-accent;
        }
      }
    }
  }
}

.tab-content {
  padding: 1.5rem 0;
}

.feed-container {
  margin-top: 1rem;
}

// Empty state component
.empty-feed {
  @include brutalist-container;
  padding: 2rem;
  text-align: center;
  margin-top: 2rem;
  
  h3 {
    @include brutalist-title;
    margin-bottom: 1rem;
  }
  
  p {
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
}

/* Dark mode overrides for empty feed */
.dark-mode .empty-feed {
  background-color: $brutalist-dark-surface;
  border-color: $brutalist-dark-border;
  
  h3 {
    color: $brutalist-dark-text;
  }
  
  p {
    color: darken($brutalist-dark-text, 15%);
  }
  
  .brutalist-primary-background {
    background-color: $brutalist-dark-accent;
  }
}
